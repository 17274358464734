import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { deletePlay, getPlaylistDetails } from '../api/playlist';
import { textOverflowEllipsisSx } from "../common/css-constants";
import { useAuth } from "../context/auth-context";
import { useDataContext } from '../context/nba-data';
import { NotePrivacy } from "../types/constants";
import { noteDto, playlistNotes } from "../types/dto";
import { games } from '../types/ui';
import { searchGameQueryPart } from "../utils/game-utils";
import PlaylistItemView from "./PlaylistItemView";

interface playlistItemsProps {
    playlistId: string;
    onBack: () => void;
}
const menuItemPaddingX = '5px;';
const menuItemPaddingY = '3px;';
export default function PlaylistItems(props: playlistItemsProps) {
    const [selectedIndex, setSelectedIndex] = useState(null as null | number);
    const [loaded, setLoaded] = useState(false);
    //   const [note, setNote] = useState(null as null | notesDto);
    const [searchParams, setSearchParams] = useSearchParams();
    const [playlistNotes, setPlaylistNotes] = useState(null as playlistNotes | null);
    // const playlistId = searchParams.get("playlistId");
    const { playlistId } = props;
    const [userSearch, setUserSearch] = useState("");
    const [title, setTitle] = useState("");
    const [privacy, setPrivacy] = useState(NotePrivacy.Public);
    const [note, setNote] = useState("");
    const auth = useAuth();
    const playsListRef = useRef<HTMLDivElement>(null);
    const [distance, setDistance] = useState(0);

    useEffect(() => {
        const element = playsListRef.current;
        if (element) {
            const rect = element.getBoundingClientRect();
            const distanceToViewportTop = rect.top + window.scrollY;
            setDistance(distanceToViewportTop);
        }
    }, [playlistNotes]);

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        setSelectedIndex(index);
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openMenuIndex, setOpenMenuIndex] = useState<null | number>(null);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, i: number) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setOpenMenuIndex(i);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setOpenMenuIndex(null);
    };
    const onBack = () => {
        handleMenuClose();
        props.onBack();
    }
    const getSetPlaylistDetails = () => {
        getPlaylistDetails(playlistId).then(details => {
            setPlaylistNotes(details);
            setTitle(details.playlist.title);
            setNote(details.playlist.note);
            setPrivacy(details.playlist.privacy);
            setLoaded(true);
            if (details.playlist.playlistItems.length > 0) setSelectedIndex(0);
        });
    }

    const onDeleteClick = async (event: React.MouseEvent<HTMLLIElement>, playlistId: string, pbpVideoId: number) => {
        event.stopPropagation();
        handleMenuClose();
        await deletePlay(playlistId, pbpVideoId);
        await getSetPlaylistDetails();
        return true;
    }
    // const navigate = useNavigate();
    // const onBackClick = () => {
    //     navigate('/' + USER_PLAYLISTS_PATH);
    // }
    const userSearchLower = userSearch.toUpperCase();


    useEffect(() => {
        if (playlistId) {
            getSetPlaylistDetails();
        } else {
            setLoaded(true);
        }
    }, [playlistId]);

    const data = useDataContext();

    // const getPbpVideo = (index: number) => {
    //     const key = index !== null ?
    //     playlistNotes?.playlist?.playlistItems[index]?.pbpVideoId : null;
    // return playlistNotes !== null && key ?
    //     playlistNotes.pbpVideosById[key] :
    //     null;
    // }
    const key = selectedIndex !== null ?
        playlistNotes?.playlist?.playlistItems[selectedIndex]?.pbpVideoId : null;
    const selectedPbpVideo = playlistNotes !== null && key ?
        playlistNotes.pbpVideoById[key] :
        null;

    const isPortrait = useMediaQuery('(orientation: portrait');

    const filteredPlaylists = useMemo(() => {
        return playlistNotes === null ? [] :
            playlistNotes.playlist.playlistItems.filter(item => {
                const pbpVideo = playlistNotes.pbpVideoById[item.pbpVideoId];
                const note = playlistNotes.notesById[item.pbpVideoId] as noteDto | undefined;
                const curGame = data.gamesBySeason[pbpVideo.season].find(
                    (x) => x.gameId === pbpVideo.gameId,
                ) as games;
                const teamsById = data.teams;
                if (userSearch.length < 3) return true;
                else {
                    const splitQuery = userSearch.split(/[\s*]+/).map((x) => x.toLocaleLowerCase());
                    return splitQuery.every(queryPart => pbpVideo.playDesc.toLocaleLowerCase().includes(queryPart) ||
                        searchGameQueryPart(curGame, teamsById, queryPart)
                    );
                }
            });
    }, [userSearch, playlistNotes]);
    const userAuth = useAuth();
    const userLoggedIn = userAuth.userId !== null;

    return (
        <Grid container textAlign={'center'}>
            {/* <MenuItem onClick={createAddToPlaylist} sx={{ paddingX: menuItemPaddingX, paddingY: menuItemPaddingY, borderBottom: '1px solid darkgray' }}>
        <AddIcon />&nbsp;New Playlist
    </MenuItem> */}
            {
                (!loaded || !playlistNotes) ?
                    <Typography variant='body1' sx={textOverflowEllipsisSx}>
                        <IconButton onClick={onBack} sx={{ marginRight: '5px' }}>
                            <ChevronLeftIcon />
                        </IconButton>
                        Loading...
                    </Typography> :
                    playlistNotes.playlist.playlistItems.length < 1 ?

                        <Typography variant='body1' sx={textOverflowEllipsisSx}>
                            <IconButton onClick={onBack} sx={{ marginRight: '5px' }}>
                                <ChevronLeftIcon />
                            </IconButton>
                            Playlist is empty
                        </Typography> :
                        <>
                            <Grid item xs={12}>
                                <Typography variant='body1' sx={textOverflowEllipsisSx}>
                                    <IconButton onClick={onBack} sx={{ marginRight: '5px' }}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                    {playlistNotes.playlist.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign='center'>
                                <TextField
                                sx={{minWidth: '300px', marginBottom: '10px'}}
                                    // onKeyDown={stopPropagation}
                                    value={userSearch}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserSearch(event.target.value);
                                    }}
                                    placeholder='Search plays'
                                    InputProps={{
                                        sx: { paddingLeft: '6px' },
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon sx={{ paddingLeft: '0px' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    color='secondary'
                                    size='small'
                                    variant="outlined"
                                />

                                {
                                    filteredPlaylists.map((item, i) => {
                                        const pbpVideo = playlistNotes.pbpVideoById[item.pbpVideoId];
                                        const note = playlistNotes.notesById[item.pbpVideoId] as noteDto | undefined;
                                        const curGame = data.gamesBySeason[pbpVideo.season].find(
                                            (x) => x.gameId === pbpVideo.gameId,
                                        ) as games;

                                        return <PlaylistItemView
                                            playlistId={playlistId}
                                            pbpVideo={pbpVideo}
                                            note={note}
                                            curGame={curGame}
                                            onDelete={getSetPlaylistDetails}
                                        />

                                    })
                                }
                            </Grid>
                        </>
            }
        </Grid>
    )
}
