import Typography from "@mui/material/Typography";
import { pbpVideoDto } from "../types/dto";
import { useDataContext } from "../context/nba-data";
import { games } from "../types/ui";
import { getAwayName, getHomeName } from "../utils/game-utils";

interface gameInfoProps {
  pbp: pbpVideoDto;
  game: games;
  showClock: boolean;
  showDate: boolean;
  showOverflow: boolean;
}

export default function GameInfo(props: gameInfoProps) {
  const { pbp, game } = props;
  const data = useDataContext();

  const teamHighlightStyle = {
    padding: "3px 5px",
    borderRadius: "4px",
    opacity: 1,
    textOverflow: props.showOverflow ? 'auto' : 'ellipsis',
  };

  return (
    <Typography
      variant="body2"
      noWrap={!props.showOverflow}
      sx={{
        textOverflow: props.showOverflow ? 'auto' : 'ellipsis',
        marginTop: '8px',
        fontSize: { xs: "0.8rem", sm: "0.875rem", md: "0.95rem" },
        pr: { xs: 0, sm: "10px" },
      }}
    >
      <span
        style={{
          ...teamHighlightStyle,
          backgroundColor: data.teams[game.homeTeamId].teamColor,
        }}
      >
        {getHomeName(game, data.teams)}
      </span>{" "}
      {pbp.homeScore} -&nbsp;
      <span
        style={{
          ...teamHighlightStyle,
          backgroundColor: data.teams[game.awayTeamId].teamColor,
        }}
      >
        {getAwayName(game, data.teams)}
      </span>{" "}
      {pbp.awayScore}&nbsp;&nbsp;
      {(props.showClock || props.showDate) && (
        <Typography variant="caption" component="span">
          {"("}
          {props.showDate && `${game.gameDate}`}
          {props.showClock && props.showDate && ` `}
          {props.showClock && `${pbp.period}Q ${pbp.gameClock}`}
          {")"}
        </Typography>
      )}
    </Typography>
  );
}
