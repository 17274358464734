import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useDataContext } from "../context/nba-data";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getUserGameViews } from "../api/user";
import { Season } from "../types/constants";
import { userGameViewsDto } from "../types/dto";
import { useDebounce } from "../utils/debounce-utils";
import UserGameProfile from "./UserGameProfile";
import UserPlayerProfile from "./UserPlayerProfile";
import UserTeamProfile from "./UserTeamProfile";
interface userProfileViewProps {
  userId: number;
}

enum ViewType {
  TEAM = "Team",
  GAME = "Game",
  PLAYER = "Player",
}
export default function UserProfileView(props: userProfileViewProps) {
  const seasons = Object.values(Season);
  const [viewType, setViewType] = useState(ViewType.TEAM);
  const [selectedSeason, setSelectedSeason] = useState(seasons[0]);
  const [gameViews, setGameViews] = useState({
    totalGameViews: {},
    userGameViews: {},
    userNotes: {},
    userSaved: {},
  } as userGameViewsDto);
  const [userSearchForDisplay, setUserSearchForDisplay] = useState("");
  const [userSearch, setUserSearch] = useState("");
  const data = useDataContext();
  const teamsById = data.teams;

  const debounceSetSearch = useDebounce(
    () => setUserSearch(userSearchForDisplay),
    300,
  );
  const onSearchChange = (newSearch: string) => {
    setUserSearchForDisplay(newSearch);
    debounceSetSearch();
  };

  const onSelectTeam = (teamName: string) => {
    onSearchChange(teamName);
    setViewType(ViewType.GAME);
  };
  useEffect(() => {
    getUserGameViews(props.userId, selectedSeason).then((res) => {
      setGameViews(res);
    });
  }, [props.userId, selectedSeason]);

  return (
    <>
      <Grid container sx={{ mt: "10px" }}>
        <Grid item xs={12} textAlign="center" marginBottom="10px">
          <ToggleButtonGroup
            size="small"
            exclusive
            sx={{ marginRight: "7px" }}
            value={viewType}
            onChange={(e, newVal) => newVal !== null && setViewType(newVal)}
            aria-label="Sort order"
          >
            <ToggleButton
              value={ViewType.TEAM}
              style={{ verticalAlign: "bottom" }}
            >
              {ViewType.TEAM}
            </ToggleButton>
            <ToggleButton
              value={ViewType.GAME}
              style={{ verticalAlign: "bottom" }}
            >
              {ViewType.GAME}
            </ToggleButton>
            <ToggleButton
              value={ViewType.PLAYER}
              style={{ verticalAlign: "bottom" }}
            >
              {ViewType.PLAYER}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} textAlign="center" sx={{ marginY: "10px" }}>
          <FormControl>
            <InputLabel color="secondary">Season</InputLabel>
            <Select
              color="secondary"
              className="search-fields"
              size="small"
              label="Season"
              value={selectedSeason}
              onChange={(e) => setSelectedSeason(e.target.value as Season)}
            >
              {seasons
                .sort((a, b) => {
                  if (a < b) return 1;
                  else if (a > b) return -1;
                  else return 0;
                })
                .map((season, i) => {
                  return (
                    <MenuItem
                      color="secondary"
                      key={`season${i}`}
                      value={season}
                    >
                      {season}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>

          <FormControl sx={{ paddingLeft: '10px' }}          >
            <TextField
              id="outlined-basic"
              label="Filter teams"
              variant="outlined"
              size="small"
              color="secondary"
              placeholder=""
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSearchChange(event.target.value)
              }
              InputLabelProps={{ shrink: true }}
              value={userSearchForDisplay}
            />
          </FormControl>
          {/* {
                    viewType !== ViewType.TEAM && <FormControl sx={{ mb: '5px' }}>
                        <InputLabel color="secondary">Team</InputLabel>
                        <Select size="small" value={selectedTeamId} color="secondary" label="Team"
                            sx={{ margin: 'auto' }}
                            onChange={(e) => setSelectedTeamId(parseInt(e.target.value?.toString()))}>
                            <MenuItem value={0}>All teams</MenuItem>
                            {
                                Object.values(teamsById).map(team => {
                                    return <MenuItem key={team.teamId} value={team.teamId}>
                                        {team.teamCity + " " + team.teamName}
                                    </MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                } */}
        </Grid>
      </Grid>

      {viewType === ViewType.TEAM && (
        <div style={{ display: viewType === ViewType.TEAM ? "block" : "none" }}>
          <UserTeamProfile
            userId={props.userId}
            season={selectedSeason}
            gameViews={gameViews}
            userSearch={userSearch}
            userSearchForDisplay={userSearchForDisplay}
            onSelectTeam={onSelectTeam}
          />
        </div>
      )}
      {viewType === ViewType.GAME && (
        <div style={{ display: viewType === ViewType.GAME ? "block" : "none" }}>
          <UserGameProfile
            userId={props.userId}
            season={selectedSeason}
            gameViews={gameViews}
            userSearch={userSearch}
            userSearchForDisplay={userSearchForDisplay}
          />
        </div>
      )}
      {viewType === ViewType.PLAYER && (
        <div
          style={{ display: viewType === ViewType.PLAYER ? "block" : "none" }}
        >
          <UserPlayerProfile
            userId={props.userId}
            season={selectedSeason}
            userSearch={userSearch}
            userSearchForDisplay={userSearchForDisplay}
          />
        </div>
      )}
    </>
  );
}
