import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useDataContext } from "../context/nba-data";

import PostsIcon from "@mui/icons-material/Assignment";
import PeopleIcon from "@mui/icons-material/People";
import VideoIcon from "@mui/icons-material/Videocam";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProfile, getUserFollows } from "../api/user";
import { textOverflowEllipsisSx } from "../common/css-constants";
import ClipFeed from "../components/ClipFeed";
import FollowButton from "../components/FollowButton";
import FollowList from "../components/FollowList";
import UserInfoProfile from "../components/UserInfoProfile";
import UserProfileView from "../components/UserProfileView";
import { useAuth } from "../context/auth-context";
import { Season } from "../types/constants";
import { followsDto, userProfileDto } from "../types/dto";
import { games } from "../types/ui";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface userProfileProps { }

enum ProfileSection {
  Posts = "Posts",
  Video = "Film",
  // Playlists = "Playlists",
  Bio = "About",
  Follows = "Network",
}

const sectionOptions = [
  // { type: ProfileSection.Bio, icon: <PersonIcon /> },
  { type: ProfileSection.Posts, icon: <PostsIcon /> },
  { type: ProfileSection.Video, icon: <VideoIcon /> },
  { type: ProfileSection.Follows, icon: <PeopleIcon /> },
];

const getPlayerGameCount = (
  teamId: string,
  playerId: number,
  games: games[],
) => {
  let count = 0;
  games.forEach((x) => {
    if (x.homeTeamId === teamId && x.homePlayerIds.has(playerId)) count++;
    else if (x.awayTeamId === teamId && x.awayPlayerIds.has(playerId)) count++;
  });
  return count;
};
export default function UserProfile(props: userProfileProps) {
  const seasons = Object.values(Season);
  const [userProfile, setUserProfile] = useState<null | userProfileDto>(null);
  const [userfollows, setUserFollows] = useState<followsDto>({
    userFollows: [],
  });
  const data = useDataContext();
  const authContext = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = Number(searchParams.get("userId"));
  const [profileSection, setProfileSection] = useState<ProfileSection>(
    ProfileSection.Bio,
  );

  useEffect(() => {
    getProfile(userId).then((res) => {
      setUserProfile(res);
      const isUserOwnProfile = userId === authContext.userId;
      const showBio = res?.bio?.trim() || userId === authContext.userId;
      const showNetwork = isUserOwnProfile || res?.privacyNetwork === false;
      const showFilm = isUserOwnProfile || res?.privacyFilm === false;
      const showPosts = isUserOwnProfile || res?.privacyPosts === false;
      if (showBio) setProfileSection(ProfileSection.Bio);
      else if (showFilm) setProfileSection(ProfileSection.Video);
      else if (showPosts) setProfileSection(ProfileSection.Posts);
      else if (showNetwork) setProfileSection(ProfileSection.Follows);
    });
    getUserFollows(userId, 0).then((res) => setUserFollows(res));
  }, [userId]);


  // # of notes, tags, Follows, views, view time
  // # of likes
  // games? players?
  const isUserOwnProfile = userId === authContext.userId;
  const showBio = (userProfile?.bio?.trim() || isUserOwnProfile);
  const showNetwork = isUserOwnProfile || userProfile?.privacyNetwork === false;
  const showFilm = isUserOwnProfile || userProfile?.privacyFilm === false;
  const showPosts = isUserOwnProfile || userProfile?.privacyPosts === false;

  const showAny = showBio || showNetwork || showFilm || showPosts;
  return (
    <>
      <Grid container maxWidth='700px' margin='auto'>
        {userProfile && (
          <>
            <Grid item xs={12} mt='5px'>
              <Grid
                container
                paddingX="20px"
                justifyContent="space-between"
              >
                <Typography
                  variant="h6"
                  display="inline-block"
                  sx={textOverflowEllipsisSx}
                >
                  {userProfile.username}
                </Typography>
                <FollowButton
                  loggedInUserId={authContext.userId}
                  followedUserId={userId}
                  userFollows={userProfile.isFollowing}
                  userCanFollow={!userProfile.privacyFollow}
                  onClick={null}
                />
              </Grid>
            </Grid>
            {
              showAny && 
              <Grid item xs={12}>
              <Box sx={{ marginTop: 1, borderBottom: 1, borderColor: 'divider', textColor: 'white' }}>
                <Tabs variant='fullWidth' textColor='inherit' value={profileSection} onChange={(e, val) => setProfileSection(val)} aria-label="basic tabs example">
                  {showBio && <Tab label={ProfileSection.Bio} value={ProfileSection.Bio} />}
                  {showFilm && <Tab label={ProfileSection.Video} value={ProfileSection.Video} />}
                  {showPosts && <Tab label={ProfileSection.Posts} value={ProfileSection.Posts} />}
                  {showNetwork && <Tab label={ProfileSection.Follows} value={ProfileSection.Follows} />}
                </Tabs>
              </Box>
            </Grid>
            }

            {/* <Avatar>{userProfile.username}</Avatar> */}
            {/* <Button color="secondary" variant="outlined" onClick={() => navigate(createUserGameProfilePath(userId))}>Game Log</Button>
                    <Button color="secondary" variant="outlined" onClick={() => navigate(createUserPlayerProfilePath(userId))}>Player Log</Button> */}
            {/* View: {userProfile.views} ViewTime: {userProfile.viewTime} */}
            {
              showBio // true isuserbio
              && <div
                style={{
                  minWidth: "100%",
                  textAlign: "center",
                  margin: "auto",
                  display:
                    profileSection === ProfileSection.Bio ? "block" : "none",
                }}
              >

                <Grid item xs={12} marginY='10px'>
                  <UserInfoProfile bio={userProfile?.bio ?? ""} isUserBio={isUserOwnProfile} />
                </Grid>
              </div>
            }
            {
              showFilm &&             <div
              style={{
                minWidth: "100%",
                textAlign: "center",
                margin: "auto",
                display:
                  profileSection === ProfileSection.Video ? "block" : "none",
              }}
            >
              <UserProfileView userId={userId} />
            </div>
            }

            {
              showPosts && <div
                style={{
                  minWidth: "100%",
                  textAlign: "center",
                  margin: "auto",
                  paddingTop: '10px',
                  display:
                    profileSection === ProfileSection.Posts ? "block" : "none",
                }}
              >
                <ClipFeed
                  showContentFilters={true}
                  showPrivacyFilters={false}
                  getNotesParams={{ userId, orderByTime: true, offset: 0 }}
                />
              </div>
            }
            {
              showNetwork && <div
                style={{
                  minWidth: "100%",
                  margin: "auto",
                  display:
                    profileSection === ProfileSection.Follows ? "block" : "none",
                }}
              >
                <FollowList follows={userfollows} />
              </div>
            }

          </>
        )}
      </Grid>
    </>
  );
}
