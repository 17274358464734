import { useEffect, useState } from "react";
import AuthFields from "./AuthFields";

import { useNavigate, useSearchParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
  changePassword,
  getCurrentUser,
  getFirebaseErrorMsg,
} from "../api/firebase";
import { useAuth } from "../context/auth-context";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { updateUserPrivacy } from "../api/auth";
import Button from "@mui/material/Button";
import { userDetailsDto, userPrivacy } from "../types/dto";
import NotePrivacySelect from "./NotePrivacySelect";
import Box from "@mui/material/Box";
import { NotePrivacy } from "../types/constants";

interface userPrivacyProps {}
export default function UserPrivacy(props: userPrivacyProps) {
  const [apiMsg, setApiMsg] = useState<string | null>(null);
  const navigate = useNavigate();
  const authContext = useAuth();
  const [userDetails, setUserDetails] = useState(authContext.userDetails);

  useEffect(() => {
    setUserDetails(authContext.userDetails);
  }, [authContext.userDetails])

  const onSave = async () => {
    if (userDetails) await updateUserPrivacy(userDetails.privacyLeader, userDetails.privacyProfile, userDetails.privacyFilm, 
      userDetails.privacyPosts, userDetails.privacyNetwork, userDetails.privacyFollow, userDetails.privacyPlaylistDl, userDetails.preferences)
    await authContext.refreshUserDetails();
  }

  const hideEverythingState = {  
    privacyLeader: true,
    privacyProfile: true,
    privacyFilm: true,
    privacyPosts: true,
    privacyNetwork: true,
    privacyFollow: true
  } as userPrivacy;
  const showEverythingState = {  
    privacyLeader: false,
    privacyProfile: false,
    privacyFilm: false,
    privacyPosts: false,
    privacyNetwork: false,
    privacyFollow: false
  } as userPrivacy;
  return (
    <Container
      component="main"
      // maxWidth="xs"
      sx={{ textAlign: "left", marginLeft: '0px', mt: "10px" }}
    >

      {
        userDetails !== null && <>
              <Button
            sx={{ marginY: '5px' }}
            color='secondary'
            type="button"
            variant="contained"
            onClick={() => setUserDetails({...userDetails, ...hideEverythingState, preferences: {...userDetails.preferences, postPrivacy: NotePrivacy.Private}})}
          >
            Private Mode
          </Button>
          <Button
            sx={{ margin: '5px',  }}
            color='secondary'
            type="button"
            variant="contained"
            onClick={() => setUserDetails({...userDetails, ...showEverythingState, preferences: {...userDetails.preferences, postPrivacy: NotePrivacy.Public }})}
          >
            Public Mode
          </Button>

          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyProfile}
            onChange={e => setUserDetails({ ...userDetails, privacyProfile: e.target.checked })} />}
            label='Hide your profile entirely from others. Does not affect whether your posts/playlists can be seen by others' />
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyPosts}
            onChange={e => setUserDetails({ ...userDetails, privacyPosts: e.target.checked })} />}
            label="Hide all posts/playlists from others. Overrides individual post/playlist privacy" />
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyFilm}
            onChange={e => setUserDetails({ ...userDetails, privacyFilm: e.target.checked })} />}
            label='Hide the "Film" section of your profile from others' />
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyNetwork}
            onChange={e => setUserDetails({ ...userDetails, privacyNetwork: e.target.checked })} />}
            label='Hide the "Network" section of your profile from others' />
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyFollow}
            onChange={e => setUserDetails({ ...userDetails, privacyFollow: e.target.checked })} />}
            label="Prevent others from following you" />
          <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyLeader}
            onChange={e => setUserDetails({ ...userDetails, privacyLeader: e.target.checked })} />}
            label="Hide yourself from the Leaderboard" />
          {/* <FormControlLabel sx={{ width: '100%', marginY: '5px' }} control={<Switch color='secondary' checked={userDetails.privacyPlaylistDl}
            onChange={e => setUserDetails({ ...userDetails, privacyPlaylistDl: e.target.checked })} />}
            label="Don't allow other users to download your playlists" /> */}
          <Box marginY='5px'>
            <NotePrivacySelect
              privacy={userDetails.preferences.postPrivacy}
              onChange={postPrivacy => setUserDetails({ ...userDetails, preferences: { ...userDetails.preferences, postPrivacy } })}
            />
            <Typography sx={{ display: 'inline-block', lineHeight: '40px', marginLeft: '10px' }}>Default privacy of new posts</Typography>
          </Box>
          <Button
            sx={{ marginY: '5px' }}
            type="submit"
            variant="contained"
            onClick={onSave}
          >
            Save
          </Button>
        </>
      }
    </Container>
  );
}
