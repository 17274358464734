import VideoIcon from "@mui/icons-material/Videocam";
import Grid from "@mui/material/Grid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDataContext } from "../context/nba-data";

import { searchGames } from "../utils/game-utils";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import { useNavigate, useSearchParams } from "react-router-dom";
import { League, Season } from "../types/constants";
import { userGameViewsDto } from "../types/dto";
import { games } from "../types/ui";
import { createGameVideoUrl } from "../utils/url-utils";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import UserGame from "./UserGame";
import { TableVirtuoso } from "react-virtuoso";
interface userGameProfileProps {
  gameViews: userGameViewsDto;
  season: Season;
  userSearch: string;
  userSearchForDisplay: string;
  userId: number;
}

type Order = "asc" | "desc";

const headCells = [
  {
    id: "date",
    label: "Game Date",
  },
  {
    id: "percent",
    label: "% Viewed",
  },
];

export const sortGame = (
  userTotalA: number,
  totalA: number,
  userTotalB: number,
  totalB: number,
) => {
  let returnVal = 0;
  const a = Math.round((userTotalA / totalA) * 100000);
  const b = Math.round((userTotalB / totalB) * 100000);
  if (b < a) returnVal = -1;
  if (b > a) returnVal = 1;
  if (b === a) {
    if (totalB < totalA) returnVal = -1;
    if (totalB > totalA) returnVal = 1;
  }
  return returnVal;
};

function getComparator(
  order: Order,
  orderBy: string,
  gameViews: userGameViewsDto,
) {
  return (gameA: games, gameB: games) => {
    let returnVal = 0;
    if (orderBy === "date") {
      const [monthA, dayA, yearA] = gameA.gameDate.split("/").map(Number);
      const [monthB, dayB, yearB] = gameB.gameDate.split("/").map(Number);
      const dateA = new Date(2000 + yearA, monthA - 1, dayA);
      const dateB = new Date(2000 + yearB, monthB - 1, dayB);
      // mm, dd, yy
      // TODO fix this, this sucks
      if (dateB < dateA) returnVal = -1;
      if (dateB > dateA) returnVal = 1;
    } else {
      returnVal = sortGame(
        gameViews.userGameViews[gameA.gameId] ?? 0,
        gameViews.totalGameViews[gameA.gameId] ?? 1,
        gameViews.userGameViews[gameB.gameId] ?? 0,
        gameViews.totalGameViews[gameB.gameId] ?? 1,
      );
    }
    return order === "desc" ? returnVal : -returnVal;
  };
}

export default function UserGameProfile(props: userGameProfileProps) {
  const [order, setOrder] = useState("desc" as Order);
  const [orderBy, setOrderBy] = useState(headCells[0].id);

  const [season, setSeason] = useState(props.season);
  const [gameViews, setGameViews] = useState(props.gameViews);
  const data = useDataContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = Number(searchParams.get("userId"));

  const [userSearch, setUserSearch] = useState(props.userSearch);
  const [userSearchForDisplay, setUserSearchForDisplay] = useState(
    props.userSearchForDisplay,
  );

  const virtuosoRef = useRef<HTMLDivElement>(null);
  const [distance, setDistance] = useState(0);

  useEffect(() => {
    const element = virtuosoRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const distanceToViewportTop = rect.top + window.scrollY;
      setDistance(distanceToViewportTop);
    }
  }, []);

  useEffect(() => setUserSearch(props.userSearch), [props.userSearch]);
  useEffect(
    () => setUserSearchForDisplay(props.userSearchForDisplay),
    [props.userSearchForDisplay],
  );
  useEffect(() => setSeason(props.season), [props.season]);
  useEffect(() => setGameViews(props.gameViews), [props.gameViews]);

  const navigate = useNavigate();

  const allPlayers = data.seasonTeamPlayers[season];
  const allGames = data.gamesBySeason[season];
  const teamsById = data.teams;

  const visibleGames = useMemo(() => {
    return allGames
      .filter((g) => searchGames(g, data.teams, userSearch))
      .sort(getComparator(order, orderBy, gameViews));
  }, [order, orderBy, gameViews, userSearch]);

  return (
    <>
      <Grid container sx={{ mt: "10px", maxWidth: "500px", margin: "auto" }}>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer ref={virtuosoRef}>
            <TableVirtuoso
              style={{
                height: `calc(100vh - ${distance}px)`,
                margin: "auto",
              }}
              data={useMemo(() => visibleGames, [visibleGames])}
              fixedHeaderContent={() => (
                <TableRow sx={{ backgroundColor: "#2b2b2b" }}>
                  {headCells.map((headCell, i) => (
                    <TableCell
                      key={"headCell" + i}
                      sx={{ paddingBottom: "2px", paddingTop: "2px" }}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() => {
                          const isAsc =
                            orderBy === headCell.id && order === "asc";
                          setOrder(isAsc ? "desc" : "asc");
                          setOrderBy(headCell.id);
                        }}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              )}
              itemContent={(i, game) => (
                <TableCell colSpan={2} sx={{ padding: 0 }}>
                  <UserGame
                    game={game}
                    navigate={navigate}
                    gameViews={gameViews}
                    season={season}
                    showUserStats={true}
                  />
                </TableCell>
              )}
            />
          </TableContainer>
          {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
        </Paper>
      </Grid>
      {
        // selectedPlayer !== null && <Grid container>
        //     {
        //         ratings.map((rating, i) => {
        //             const setRating = (newVal: number | null) => {
        //                 ratings[i].score = newVal;
        //                 setRatings([...ratings]);
        //             }
        //             return (<RatingScore type={rating.name} score={rating.score} setRating={setRating} />);
        //         })
        //     }
        // </Grid>
      }
    </>
  );
}
