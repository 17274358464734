import { useEffect, useState } from "react";
import ClipFeed from "../components/ClipFeed";
import { noteAndPbpDto, playlistNotes } from "../types/dto";
import { getNotes } from "../api/pbp-user";
import ClipView from "../components/ClipView";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { getPlaylistDetails } from "../api/playlist";
import PlaylistClipView from "../components/PlaylistClipView";
import { PLAYLIST_ID, PLAYLIST_ITEM_ID } from "../utils/url-utils";

interface playlistProps { }


export default function Playlist(props: playlistProps) {
    const [loaded, setLoaded] = useState(false);
    const [playlist, setPlaylist] = useState(null as null | playlistNotes);
    const [searchParams, setSearchParams] = useSearchParams();
    const playlistItemQs = searchParams.get(PLAYLIST_ITEM_ID);
    const playlistItem = playlistItemQs ? Number(playlistItemQs) : null;
    const playlistId = searchParams.get(PLAYLIST_ID);
    if (!playlistId) {

    }
    useEffect(() => {
        if (playlistId) {
            getPlaylistDetails(playlistId).then(res => {
                setLoaded(true);
                setPlaylist(res);
            })
        }
    }, []);
    let content = null;
    if (!loaded) content = <Typography variant="body2">Loading...</Typography>;
    else if (playlist === null)
        content = <Typography variant="body2">Playlist doesn't exist</Typography>;
    else content = <PlaylistClipView
        loadPlaylistDetails={false}
        playlist={playlist.playlist}
        startingPbpVideoId={playlistItem}
        notesById={playlist.notesById}
        pbpVideoById={playlist.pbpVideoById} />
    return content;
}
