import { debounce } from "@mui/material/utils";
import { useEffect, useMemo, useRef } from "react";

export const useDebounce = (callback: Function, waitMs: number) => {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, waitMs);
  }, []);

  return debouncedCallback;
};
