import { NotePrivacy } from "../types/constants";
import { playlistItem, playlistMetaDto, playlistNotes, playlistWithFirstItemDto } from "../types/dto";
import { PLAYLIST_PREFIX, apiDelete, apiGet, apiPostJson, apiPutJson } from "./utils";

export const getUserPlaylists = async (
    offset: number
) => {
    const response = await apiGet(
        `${PLAYLIST_PREFIX}/user?offset=${offset}`,
    );
    const playlistMeta: playlistMetaDto = await response.json();
    return playlistMeta;
};

export const getPlaylistDetails = async (
    playlistId: string
) => {
    const response = await apiGet(
        `${PLAYLIST_PREFIX}/playlistdetails?playlistId=${playlistId}`,
    );
    const playlistMeta: playlistNotes = await response.json();
    return playlistMeta;
};

export const createAddPlaylists = async (
    title: string,
    note: string,
    privacy: NotePrivacy,
    pbpVideoIds: number[],
    teamId: number | null,
    playerId: number | null,
) => {
    const response = await apiPostJson(
        `${PLAYLIST_PREFIX}/create`,
        {
            pbpVideoIds,
            title,
            note,
            privacy,
            teamId,
            playerId
        }
    );
    await response;
    return true;
}

export const updatePlaylistDetails = async (
    playlistId: string,
    note: string,
    title: string,
    privacy: NotePrivacy,
    teamId: number | null,
    playerId: number | null,
) => {
    const response = await apiPutJson(
        `${PLAYLIST_PREFIX}/details`,
        {
            playlistId,
            note,
            title,
            privacy,
            teamId,
            playerId
        }
    );
    await response;
    return true;
}
export const addToPlaylist = async (
    playlistId: string,
    pbpVideoId: number,
) => {
    const response = await apiPostJson(
        `${PLAYLIST_PREFIX}/add`,
        {
            playlistId,
            pbpVideoId,
        }
    );
    await response;
    return true;
}

export const deletePlaylist = async (playlistId: string) => {
    const response = await apiDelete(`${PLAYLIST_PREFIX}/playlist?playlistId=${playlistId}`);
    return true;
}

export const deletePlay = async (playlistId: string, pbpVideoId: number) => {
    const response = await apiDelete(`${PLAYLIST_PREFIX}/play?playlistId=${playlistId}&pbpVideoId=${pbpVideoId}`);
    return true;
}