import { useState } from "react";
// import logo from './logo.svg';

import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { playerUI } from "../../api/nba";
import { useDataContext } from "../../context/nba-data";
import { pbpVideoDto } from "../../types/dto";

interface shotTypeFilterProps {
  selectedShotTypes: string[];
  setSelectedShotTypes: (newShotTypes: string[]) => void;
}

export default function ShotTypeFilter(props: shotTypeFilterProps) {
  const [shotTypeInput, setShotTypeInput] = useState('');

  const data = useDataContext();
  const shotTypesAndEVAT = data.shotTypes;
  const shotTypes = Object.keys(shotTypesAndEVAT);
  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          color="secondary"
          disableCloseOnSelect
          fullWidth
          size="small"
          limitTags={1}
          multiple
          value={props.selectedShotTypes}
          inputValue={shotTypeInput}
          onInputChange={(e, newVal, reason) => {
            if (reason !== "reset") setShotTypeInput(newVal)
          }}
          onChange={(e, newVal) => {
            props.setSelectedShotTypes(newVal);
          }}
          options={shotTypes}
          // blurOnSelect={false}
          // clearOnBlur={false}

          getOptionLabel={(playType) => playType}
          filterSelectedOptions
          renderInput={(params) => {
            return (
              <TextField
                onBlur={() => setShotTypeInput('')}
                {...params}
                color="secondary"
                InputLabelProps={{ shrink: true }}
                label="Shot type"
                // placeholder="Favorites"
              />
            );
          }}
        />
      </Grid>
    </>
  );
}
