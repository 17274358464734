import { useState } from "react";
// import logo from './logo.svg';

import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { playerUI } from "../api/nba";
import { useDataContext } from "../context/nba-data";
import { League, Season } from "../types/constants";
import { games } from "../types/ui";
import {
  getGameText,
  getGameTextNoSpoilers,
  searchGames,
} from "../utils/game-utils";
import { createGameVideoUrl } from "../utils/url-utils";
import SeasonSelector from "./menu/SeasonSelector";
import GameMenu from "./GameMenu";

const drawerWidth: number = 600;
enum SearchType {
  Game,
  Play,
  Search,
  Query,
  Misc,
  Login,
  Fake,
}

interface GameSeasonMenuProps {
  selectedSeason: Season;
  selectedGameId: number | null;
  // onSearch: (data: {}, isNba: boolean, season: string, ordsq: string) => void,
  // spoilers: boolean,
  // toggleSpoilers: () => void,
  // fullscreen: boolean,
  // toggleFullscreen: () => void,
  // randomize: boolean,
  // toggleRandomize: () => void
}

function GameSeasonMenu(props: GameSeasonMenuProps) {
  const data = useDataContext();
  const [searchType, setSearchType] = useState<SearchType>(SearchType.Game);

  const seasons = Object.values(Season);
  const [selectedSeason, setSelectedSeason] = useState(props.selectedSeason);
  const [selectedGame, setSelectedGame] = useState(
    data.gamesBySeason[selectedSeason]?.find(
      (x) => x.gameId === props.selectedGameId,
    ) ?? (null as games | null),
  );
  const navigate = useNavigate();
  const onSelect = (game: games | null) => {
    setSelectedGame(game);
    if (game !== null) {
      navigate(
        createGameVideoUrl(League.NBA, selectedSeason, game.gameId, false),
      );
    }
  };

  // on change season - clear all
  // on change player - game should still be valid...?
  // on change game - player should still be valid...?

  // each game you select should also filter out the games to only ones where the player played
  let validGames = data.gamesBySeason[selectedSeason];

  return (
    <>
      <Grid container justifyContent="center">
        <Grid item xs={4}>
          <SeasonSelector
            selectedSeason={selectedSeason}
            onSelectSeason={setSelectedSeason}
          />
        </Grid>
        <Grid item xs={8}>
          <GameMenu selectedGames={selectedGame}
            maxTags={1}
            setSelectedGames={games => {
              onSelect(games.length > 0 ? games[0] : null)
            }}
            validGames={validGames}
          />
        </Grid>

        {/* <Autocomplete
          filterOptions={(options, { inputValue }) => {
            return options.filter((game) =>
              searchGames(game, data.teams, inputValue),
            );
          }}
          size="small"
          limitTags={1}
          sx={{
            display: "inline-block",
            maxWidth: "350px",
            verticalAlign: "top",
            flexShrink: 2,
            flexGrow: 1,
          }}
          value={selectedGame}
          onChange={(e, newVal) => onSelect(newVal)}
          options={validGames}
          blurOnSelect
          getOptionLabel={(option) =>
            true
              ? getGameText(option, data.teams)
              : getGameTextNoSpoilers(option, data.teams)
          }
          filterSelectedOptions
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                color="secondary"
                label="Game"
                placeholder="Search for a game"
              />
            );
          }}
        /> */}
      </Grid>

      {/* <Button variant="contained" onClick={handleClick}>
                    Submit
                </Button>
                <Button>Reset</Button> */}
    </>
  );
}

export default GameSeasonMenu;
