import { UI_URL_PREFIX, URL_PREFIX } from "../api/utils";
import { Comparers, League, PlayerPlayTypes, Season, SortType } from "../types/constants";

export const GAME_VIDEO_PATH = "/gamevideo";
export const createGameVideoUrl = (
  league: League,
  season: Season,
  gameId: number,
  newOnly: boolean,
) => {
  return `${GAME_VIDEO_PATH}?league=${league}&season=${season}&gameId=${gameId}${newOnly ? "&new" : ""}`;
};

export const PLAYER_VIDEO_PATH = "/playervideo";
interface createPlayerVideoUrlParams {
  league: League,
  season: Season,
  playerId: number,
  teamId: string,
  playTypes: PlayerPlayTypes[],
  gameIds: number[],
  lineupPlayerIds: number[],
  shotTypes: number[],
  newOnly: boolean,
  sort: SortType
}
export const createPlayerVideoUrl = (
params: createPlayerVideoUrlParams
) => {
  const playTypesQs = `&playTypes=${params.playTypes.join(',')}`;
  const shotTypesQs = params.shotTypes.length > 0 ? `&shotTypes=${params.shotTypes.join(',')}` : ``;
  const gameIdsQs = params.gameIds.length > 0 ? `&gameIds=${params.gameIds.join(',')}` : ``;
  const lineupPlayerIdsQs = params.lineupPlayerIds.length > 0 ? `&lineupPlayerIds=${params.lineupPlayerIds.join(',')}` : ``;
  return `${PLAYER_VIDEO_PATH}?league=${params.league}&season=${params.season}&teamId=${params.teamId}&playerId=${params.playerId}&sort=${params.sort}${playTypesQs}${shotTypesQs}${gameIdsQs}${lineupPlayerIdsQs}${params.newOnly ? "&new" : ""}`;
};

// league: z.nativeEnum(League),
// season: z.nativeEnum(Season),
// playerId: z.number(),
// teamId: z.number(),
// playTypes: z.array(z.string()),
// gameIds: z.array(z.number()),
// lineupPlayerIds: z.array(z.number()),
// scoreDiffType: z.string(), // TODO
// scoreDiff: z.number(),
// shotTypes: z.array(z.string()) // TODO
export const USER_PROFILE_PATH = "/user";
export const createUserProfilePath = (userId: number) => {
  return `${USER_PROFILE_PATH}?userId=${userId}`;
};

export const USER_TEAM_PROFILE_PATH = "/userteam";
export const createUserTeamProfilePath = (userId: number) => {
  return `${USER_TEAM_PROFILE_PATH}?userId=${userId}`;
};

export const USER_GAME_PROFILE_PATH = "/usergame";
export const createUserGameProfilePath = (userId: number) => {
  return `${USER_GAME_PROFILE_PATH}?userId=${userId}`;
};

export const USER_PLAYER_PROFILE_PATH = "/userplayer";
export const createUserPlayerProfilePath = (userId: number) => {
  return `${USER_PLAYER_PROFILE_PATH}?userId=${userId}`;
};

export const CLIP_TIMELINE_PATH = "/";

export const LOGIN_PATH = "/login";

export const LEADERS_PATH = "/leaders";

export const RESET_PASSWORD_PATH = "/reset";

export const CREATE_USER_PATH = "/createuser";

export const USER_SETTINGS_PATH = "/usersettings";

export const USER_PLAYLISTS_PATH = "/playlists";

export const REGISTER_PATH = "/register";

export const TERMS_AND_CONDITIONS_PATH = "/terms";
export const PRIVACY_POLICY_PATH = "/privacy";

export const FILM_PATH = "/film";

export const CLIP_PATH = "c";
export const CLIP_USER = "u";
export const CLIP_PBP = "p";
const createClipPath = (userId: number, pbpVideoId: number) => {
  return `${CLIP_PATH}?${CLIP_USER}=${userId}&${CLIP_PBP}=${pbpVideoId}`;
};

export const createClipPathExternal = (userId: number, pbpVideoId: number) => {
  return `${UI_URL_PREFIX}/${createClipPath(userId, pbpVideoId)}`;
};


export const PLAYLIST_PATH = "p";
export const PLAYLIST_ID = "p";
export const PLAYLIST_ITEM_ID = "i";
const createPlaylistPath = (playlistId: string, pbpVideoId: number | null) => {
  const pbpVideoClause = pbpVideoId ? `&${PLAYLIST_ITEM_ID}=${pbpVideoId}` : '';
  return `${PLAYLIST_PATH}?${PLAYLIST_ID}=${playlistId}${pbpVideoClause}`;
};

export const createPlaylistPathExternal = (playlistId: string, pbpVideoId: number | null) => {
  return `${UI_URL_PREFIX}/${createPlaylistPath(playlistId, pbpVideoId)}`;
};

export const createRedditShareUrl = (content: string, url: string) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedContent = encodeURIComponent(content);
  // https://www.reddit.com/submit?url=https%3A%2F%2Ffanspo.com%2Fnba%2Fs%2Fknicks%2Fgrids%2FlG08LRz1VkRFn0%2Fbuild-a-start-5-of-only-players-still-in-the-playoffs&title=Build%20a%20Start%205%20Of%20Only%20Players%20Still%20in%20the%20Playoffs!
  return `https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedContent}`;
};

export const createTwitterShareUrl = (content: string, url: string) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedContent = encodeURIComponent(content);
  // https://twitter.com/intent/post?url=https%3A%2F%2Ffanspo.com%2Fnba%2Fs%2Fknicks%2Fgrids%2FlG08LRz1VkRFn0%2Fbuild-a-start-5-of-only-players-still-in-the-playoffs&text=Build+a+Start+5+Of+Only+Players+Still+in+the+Playoffs%21%0A%0A&hashtags=Fanspo
  return `https://twitter.com/intent/post?url=${encodedUrl}&text=${encodedContent}&hashtags=Crowdscout`;
};

export const createFacebookShareUrl = (url: string) => {
  const encodedUrl = encodeURIComponent(url);
  // https://www.facebook.com/share_channel/?link=https%3A%2F%2Ffanspo.com%2Fnba%2Fs%2Fknicks%2Fgrids%2FlG08LRz1VkRFn0%2Fbuild-a-start-5-of-only-players-still-in-the-playoffs&app_id=966242223397117&source_surface=external_reshare&display&hashtag=Fanspo
  return `https://www.facebook.com/share_channel?link=${encodedUrl}&source_surface=external_reshare&display&hashtag=Crowdscout`;
};

export const createBlueskyShareUrl =  (content: string, url: string) => {
  const encodedUrl = encodeURIComponent(url);
  const encodedContent = encodeURIComponent(content);
  return `https://bsky.app/intent/compose?text=${encodedContent}%20${encodedUrl}`
  return `https://www.facebook.com/share_channel?link=${encodedUrl}&source_surface=external_reshare&display&hashtag=Crowdscout`;
};