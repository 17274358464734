// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { FirebaseError, initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  NextOrObserver,
  User,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword,
} from "firebase/auth";
import { AccountLevel, accountAuthorizationsByLevel } from "../types/user-authorization";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAjQlFEVK8l7UfX99QCCDwFpVePa04Xxf0",
  authDomain: "crowdscout-681cc.firebaseapp.com",
  projectId: "crowdscout-681cc",
  storageBucket: "crowdscout-681cc.appspot.com",
  messagingSenderId: "292738304037",
  appId: "1:292738304037:web:ca9cdfe6a4850a6af057cc",
  measurementId: "G-J6FJBJCX8R",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const auth = getAuth();

export const onAuthStateChangeCb = (cb: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, cb);
};

export const getCurrentUser = async () => {
  await auth.authStateReady();
  return auth.currentUser
};

export const getUserId = async () => {
  await auth.authStateReady();
  const result = await auth.currentUser?.getIdTokenResult();
  const toReturn = (result?.claims.csId as number | undefined) ?? null;
  return toReturn;
};

export const getAccountAuth = async () => {
  await auth.authStateReady();
  const result = await auth.currentUser?.getIdTokenResult();
  const acc = (result?.claims.AccountLevel ?? AccountLevel.BASIC) as AccountLevel;
  return accountAuthorizationsByLevel[acc];
};

export const getUserToken = async (refreshToken: boolean) => {
  await auth.authStateReady();
  return await auth.currentUser?.getIdToken(refreshToken);
}

const provider = new GoogleAuthProvider();
export const loginWithGoogle = async () => {
  const result = await signInWithPopup(auth, provider);
  return result;
  // This gives you a Google Access Token. You can use it to access the Google API.
  // const credential = GoogleAuthProvider.credentialFromResult(result);
  // The signed-in user info.
  // const user = result.user;
  // IdP data available using getAdditionalUserInfo(result)
  // ...
};

export const createUserWithEmailPassword = async (
  email: string,
  password: string,
) => {
  return await createUserWithEmailAndPassword(auth, email, password);
};

export const loginUser = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

export const logoutUser = async () => {
  return await signOut(auth);
};

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "https://www.example.com/finishSignUp?cartId=1234",
  // This must be true.
  handleCodeInApp: true,
};

// TODO figure out redirect URL
export const sendEmailSignInLink = async (email: string) => {
  return await sendSignInLinkToEmail(auth, email, actionCodeSettings);
};

export const sendVerifyEmail = async () => {
  const user = auth.currentUser;
  if (user) {
    // TODO provide options object with continue URL that works. One where we don't get "unauthorized-continue-url" {url: 'https://www.crowdscout.net/login'}
    return await sendEmailVerification(user);
  }
};

export const changePassword = async (newPassword: string) => {
  const user = auth.currentUser;
  if (user) {
    return await updatePassword(user, newPassword);
  } else {
    return null;
  }
};

export const resetPassword = async (email: string) => {
  return await sendPasswordResetEmail(auth, email);
};

export const convertErrCodeForDisplay = (err: FirebaseError) => {
  if (err.code === "auth/requires-recent-login") {
    return "Requires recent login. Please logout and login before trying again";
  }
  const errCodeParts = err.code.split("/");
  const words = errCodeParts[1]
    .split("-")
    .map((word, i) =>
      i > 0 ? word : word.charAt(0).toUpperCase() + word.slice(1),
    );
  return words.join(" ");
};

export const getFirebaseErrorMsg = (err: unknown) => {
  const error = err as FirebaseError;
  try {
    return convertErrCodeForDisplay(error);
  } catch (innerErr) {
    console.log(err, innerErr);
    return "An error occurred";
  }
};
