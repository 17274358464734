export const BOTTOM_NAV_SIZE_PX = 64;
export const APPBAR_HEIGHT = 64;

export const textOverflowEllipsisSx = {
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
}

const MAX_VIDEO_SIZE_PX = 1280;

export const calculateMaxVideoSize = () => {
    return Math.min((window.innerHeight - APPBAR_HEIGHT)*0.65, 720) / 720 * MAX_VIDEO_SIZE_PX;
}

export const MAX_POST_SIZE_PX = 800;