import { Season } from "../types/constants";
import {
  followsDto,
  userDto,
  userProfileDto,
  viewLeaderDto,
} from "../types/dto";
import { URL_PREFIX, USER_PREFIX, apiGet, apiPostJson } from "./utils";

export interface loginExpiration {
  expirationTime: string;
}

export const getProfile = async (userId: number) => {
  const res = await apiGet(`${USER_PREFIX}/profile?userId=${userId}`);
  if (res.status > 399) return null;
  else return (await res.json()) as userProfileDto;
};

export const getViewLeaders = async (teamId: number | null) => {
  const teamQs = teamId ? `?teamId=${teamId}` : ``;
  const res = await apiGet(`${USER_PREFIX}/viewleaders${teamQs}`);
  const body = (await res.json()) as viewLeaderDto;
  return body;
};

export const getUserGameViews = async (userId: number, season: Season) => {
  const res = await apiGet(
    `${USER_PREFIX}/gameviews?season=${season}&userId=${userId}`,
  );
  const body = await res.json();
  return body;
};

export const getUserPlayerViews = async (userId: number, season: Season) => {
  const res = await apiGet(
    `${USER_PREFIX}/playerviews?season=${season}&userId=${userId}`,
  );
  const body = await res.json();
  return body;
};

export const getUserFollows = async (userId: number, offset: number) => {
  const res = await apiGet(
    `${USER_PREFIX}/follows?userId=${userId}&offset=${offset}`,
  );
  const body = (await res.json()) as followsDto;
  return body;
};
