interface videoBaseProps {
    vidRef: React.RefObject<HTMLVideoElement>;
    onLoadedData: () => void;
    onPause: () => void;
    onPlay: () => void;
    onEnded: () => void;
    videoHeight: number;
    videoUrl: string;
}
export default function VideoBase(props: videoBaseProps) {
    return (

        <video
        autoPlay={true}
        controls
        muted
        playsInline={true}
        ref={props.vidRef}
        style={{
          // maxHeight: window.innerHeight * 0.5,
          minHeight: `${props.videoHeight}px`,
          maxWidth: "100%",
          minWidth: "100%",
        }}
        src={props.videoUrl}
        onLoadedData={props.onLoadedData}
        onPause={() => {
props.onPause();
        }}
        onPlay={props.onPlay}
        onEnded={
          props.onEnded
        }
      />
    )
}