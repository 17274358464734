import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { calculateMaxVideoSize } from "../common/css-constants";
import { useAuth } from "../context/auth-context";
import {
  createUserProfilePath
} from "../utils/url-utils";
import FollowButton from "./FollowButton";
interface clipPlaylistViewWrapperProps {
  username: string;
  ownerUserId: number;
  userFollows: boolean;
  userCanFollow: boolean;
  itemDate: Date;
  children: ReactJSXElement
}
export default function ClipPlaylistViewWrapper(props: clipPlaylistViewWrapperProps) {
  const userAuth = useAuth();
  const userId = userAuth?.userId ?? null;

  const userProfilePath = createUserProfilePath(props.ownerUserId);
  const dt = DateTime.fromJSDate(new Date(props.itemDate));
  const formattedTimeAgo = dt.toRelative();
  const border = "1px solid grey";

  return (
    <Card
      sx={{
        maxWidth: calculateMaxVideoSize(),
        paddingBottom: "10px",
        margin: "auto",
        background: "none",
        borderBottom: border,
        borderRadius: "0px",
      }}
    >
      <CardHeader
        sx={{ pl: '10px', pr: '10px', pt: "10px", pb: "10px" }}
        // avatar={
        //   <Link
        //     to={userProfilePath}
        //     style={{ color: "white", textDecoration: "none" }}
        //   >
        //     {/* <Avatar>{note.username}</Avatar> */}
        //   </Link>
        // }
        title={
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Typography variant="body1" sx={{ verticalAlign: "center" }}>
              <Link to={userProfilePath} style={{ color: "white" }}>
                {props.username}
              </Link>
              <Typography variant="caption">
                &nbsp;&nbsp;{formattedTimeAgo}
              </Typography>
            </Typography>
            <FollowButton
              onClick={null}
              loggedInUserId={userId}
              followedUserId={props.ownerUserId}
              userFollows={props.userFollows}
              userCanFollow={props.userCanFollow}
            />
          </Grid>
        }
      />
      {props.children}
    </Card>
  );
}
