import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { updateUserBio } from "../api/auth";
import { MAX_POST_SIZE_PX } from "../common/css-constants";

interface userInfoProfileProps {
    bio: string;
    isUserBio: boolean;
}

export default function UserInfoProfile(props: userInfoProfileProps) {
    const [bio, setBio] = useState(props.bio);
    const [isEdited, setIsEdited] = useState(false);

    return <Grid container>
        <Grid item xs={12}>
            <TextField
                label={"About me"}
                color='secondary'
                inputProps={{
                    maxLength: 3000,
                    style: { color: "white", WebkitTextFillColor: "white", fontSize: '0.85rem' },
                }}
                // InputLabelProps={{style: {fontSize: '0.85rem'}}}
                disabled={!props.isUserBio}
                sx={{ maxWidth: MAX_POST_SIZE_PX, width: "100%" }}
                multiline
                size="small"
                minRows={1}
                //   maxRows={4}
                variant="outlined"
                value={bio}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setIsEdited(true);
                    setBio(event.target.value);
                }}
            />
        </Grid>
        {
            props.isUserBio &&
            <Grid item xs={12} textAlign={'right'} marginTop='8px'>
                <Button
                    disabled={!isEdited}
                    variant="contained"
                    onClick={() => {
                        setIsEdited(false);
                        updateUserBio(bio);
                    }}
                >
                    Edit
                </Button>
            </Grid>
        }

    </Grid>




}
