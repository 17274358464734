import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import FacebookIcon from "@mui/icons-material/Facebook";
import RedditIcon from "@mui/icons-material/Reddit";
import ShareIcon from "@mui/icons-material/Share";
import TwitterIcon from "@mui/icons-material/Twitter";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import {
    copyTextToClipboard,
    fallbackCopyTextToClipboard
} from "../utils/clipboard-utils";
import {
    createBlueskyShareUrl,
    createFacebookShareUrl,
    createRedditShareUrl,
    createTwitterShareUrl
} from "../utils/url-utils";
import DownloadIcon from '@mui/icons-material/Download';
import { downloadResource } from "../utils/download-utils";
import { pbpVideoDto } from "../types/dto";
import MultiDownload from "./MultiDownload";
import { useDataContext } from "../context/nba-data";
import { useAuth } from "../context/auth-context";

interface shareMenuProps {
    shareText: string;
    linkPath: string,
    pbpVideo: pbpVideoDto | undefined,
    playlistPbpVideos: pbpVideoDto[]
    // children: ReactJSXElement
}

export default function ShareMenu(props: shareMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { linkPath, shareText } = props;

    const onReddit = () => {
        window.open(createRedditShareUrl(shareText, linkPath), "_blank");
        handleClose();
    };
    const onFacebook = () => {
        window.open(createFacebookShareUrl(linkPath), "_blank");
        handleClose();
    };
    const onTwitter = () => {
        window.open(createTwitterShareUrl(shareText, linkPath), "_blank");
        handleClose();
    };
    const onBluesky = () => {
        window.open(createBlueskyShareUrl(shareText, linkPath), "_blank");
        handleClose();
    }
    const onCopy = () => {
        copyTextToClipboard(linkPath);
        handleClose();
    };
    const data = useDataContext();

    const onDownload = () => {
        if (props.pbpVideo)  {
            const season = props.pbpVideo.season;
            const gameId = props.pbpVideo.gameId;
            const game = data.gamesBySeason[season].find(x => x.gameId === gameId);
            if (game) {
                const homeTeam = data.teams[game.homeTeamId]
                const awayTeam = data.teams[game.awayTeamId];
                downloadResource(props.pbpVideo, awayTeam.triCode, homeTeam.triCode);
            }
        }
    }

    const authContext = useAuth();
    return (
        <>
            <IconButton
                aria-label="share"
                onClick={handleClick}
                sx={{ fontSize: "1.2rem" }}
            >
                <ShareIcon sx={{ fontSize: "inherit" }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={onTwitter} component='button' sx={{width: '100%'}}>
                    <ListItemIcon>
                        <TwitterIcon />
                    </ListItemIcon>
                    Twitter
                </MenuItem>
                <MenuItem onClick={onReddit} component='button' sx={{width: '100%'}}>
                    <ListItemIcon>
                        <RedditIcon />
                    </ListItemIcon>
                    Reddit
                </MenuItem>
                <MenuItem onClick={onFacebook} component='button' sx={{width: '100%'}}>
                    <ListItemIcon>
                        <FacebookIcon />
                    </ListItemIcon>
                    Facebook
                </MenuItem>
                <MenuItem onClick={onBluesky} component='button' sx={{width: '100%'}}>
                    <ListItemIcon >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" focusable='false' height='1em' width='1em'
                    style={{
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                        userSelect: "none",
                        width: "1em",
                        height: "1em",
                        display: "inline-block",
                        fill: "currentColor",
                        WebkitFlexShrink: 0,
                        flexShrink: 0,
                        WebkitTransition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        fontSize: "1.5rem",
                      }}
                    // className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'
                    >
                        <path d="M111.8 62.2C170.2 105.9 233 194.7 256 242.4c23-47.6 85.8-136.4 144.2-180.2c42.1-31.6 110.3-56 110.3 21.8c0 15.5-8.9 130.5-14.1 149.2C478.2 298 412 314.6 353.1 304.5c102.9 17.5 129.1 75.5 72.5 133.5c-107.4 110.2-154.3-27.6-166.3-62.9l0 0c-1.7-4.9-2.6-7.8-3.3-7.8s-1.6 3-3.3 7.8l0 0c-12 35.3-59 173.1-166.3 62.9c-56.5-58-30.4-116 72.5-133.5C100 314.6 33.8 298 15.7 233.1C10.4 214.4 1.5 99.4 1.5 83.9c0-77.8 68.2-53.4 110.3-21.8z"/></svg>
                    </ListItemIcon>
                    Bluesky
                </MenuItem>
                <MenuItem onClick={onCopy} component='button' sx={{width: '100%'}}>
                    <ListItemIcon>
                        <ContentPasteIcon />
                    </ListItemIcon>
                    Copy Link
                </MenuItem>
                {
                    props.pbpVideo && <MenuItem onClick={onDownload} component='button' sx={{ width: '100%' }}>
                        <ListItemIcon>
                            <DownloadIcon />
                        </ListItemIcon>
                        Download
                    </MenuItem>
                }
                {
                    props.playlistPbpVideos.length > 0 && authContext.accountLevel.canBulkDownload && <MultiDownload plays={props.playlistPbpVideos} />
                }
            </Menu>
        </>
    );
}
