export function fallbackCopyTextToClipboard(text: string) {
  console.log('fallbackCopyTextToClipboard')
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    if (!successful) console.log("Fallback: Copying text command failed");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text: string) {
  console.log('transient activation', navigator);
  try {
    // @ts-ignore
    navigator.permissions.query({ name: "clipboard-write" }).then(permissionStatus => {
      if (permissionStatus.state === "granted") {
        console.log("Permission to write to clipboard is granted.");
      } else if (permissionStatus.state === "prompt") {
        console.log("Permission to write to clipboard is not granted yet. The user will be prompted.");
      } else {
        console.log("Permission to write to clipboard is denied.");
      }
    });
  } catch (err) { }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  return navigator.clipboard
    .writeText(text)
    .then(x => {
      console.log('copied');
      return true;
    })
    .catch((err) => console.error("Async: Could not copy text: ", err));
}
