import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { getGameVideo } from "../api/pbp";
import VideoList from "../components/VideoList";
import { useAuth } from "../context/auth-context";
import { League, Season } from "../types/constants";
import {
  pbpVideoLineups
} from "../types/dto";

interface gameVideoProps {}

export default function GameVideo(props: gameVideoProps) {
  const [gamesPbp, setGamesPbp] = useState({} as pbpVideoLineups);
  const [searchParams, setSearchParams] = useSearchParams();
  const [gameId, setGameId] = useState(
    searchParams.get("gameId") ? Number(searchParams.get("gameId")) : null,
  );

  const fetchData = () => {
    const seasonQs = searchParams.get("season") as Season;
    const gameIdQsRaw = searchParams.get("gameId");
    const gameIdQs = Number(gameIdQsRaw);
    setGameId(gameIdQs);
    if (seasonQs !== null && gameIdQsRaw !== null)
      getGameVideo(League.NBA, seasonQs, gameIdQs).then((x) => setGamesPbp(x));
    // TODO this needs to also reset your workspace state
  };
  useEffect(fetchData, [useLocation()]);

  const userAuth = useAuth();
  return (
    gamesPbp.pbpVideo?.length === 0 ? <div>No videos available</div> : <VideoList
      gamesPbp={gamesPbp}
      getNotesParams={{
        gameId,
        userId: userAuth.userId,
        orderByTime: true,
        offset: 0,
      }}
    />
  );
}
