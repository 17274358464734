import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { pbpVideoDto } from '../types/dto';
import { maxDownloadBatchSize, zipAndDownload } from '../utils/download-utils';

import DownloadingIcon from '@mui/icons-material/Downloading';
import { useDataContext } from "../context/nba-data";
import { games } from "../types/ui";

interface Props {
    plays: pbpVideoDto[];
}

const MultiDownload: React.FC<Props> = ({ plays }) => {
    const [startIndex, setStartIndex] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        setStartIndex(0);
    }, [plays]);

    const data = useDataContext();

    const onClick = async () => {
        if (plays.length > 0) {
            const games = plays.map(x => data.gamesBySeason[x.season].find(y => y.gameId === x.gameId)).filter(x => x !== undefined) as games[];
            const homeTeams = games.map(x => data.teams[x.homeTeamId].triCode);
            const awayTeams = games.map(x => data.teams[x.awayTeamId].triCode);
            setIsDownloading(true);
            await zipAndDownload(plays, awayTeams, homeTeams, startIndex);
            setStartIndex(startIndex + maxDownloadBatchSize);
            setIsDownloading(false);
        }
    };

    // const playlistDownloaded = startIndex > plays.length - 1;
    // const maxSize = Math.min(startIndex + 1 + maxDownloadBatchSize, plays.length);
    // const currVideos = `${startIndex + 1}-${maxSize}`;
    // if (isDownloading) buttonText = `Downloading videos ${currVideos}`;
    // else if (startIndex === 0) buttonText = "Bulk Download";
    // else if (playlistDownloaded) buttonText = "Playlist downloaded";
    // else buttonText = `Bulk Download videos ${currVideos}`;

    let buttonText = '';
    const playlistDownloaded = !isDownloading && startIndex !== 0;

    if (isDownloading) buttonText = `Downloading...`;
    else if (startIndex === 0) buttonText = "Download Playlist";
    else buttonText = "Playlist downloaded";

    return (
        <MenuItem onClick={onClick} disabled={isDownloading || playlistDownloaded} component='button' sx={{ width: '100%' }}>
            <ListItemIcon>
                <DownloadingIcon />
            </ListItemIcon>
            {buttonText}
        </MenuItem>
    );
}


// async function DownloadCombinedVids(ref : React.RefObject<HTMLVideoElement>) {
//     const blob = new Blob([], {type: 'video/mp4'});
//     const blobUrl = URL.createObjectURL(blob);
//     const source = new MediaSource();
//     const video = ref.current;
//     if (video === null) throw new Error('akljsdfklajsdf');
//     //video.src = blobUrl;
//     video.src = URL.createObjectURL(source);

//     URL.createObjectURL(source);

//     const sourceBuffer = await new Promise<SourceBuffer>((resolve, reject) => {
//         const getSourceBuffer = () => {
//             try {
//                 const sb = source.addSourceBuffer(`video/mp4; codecs="avc1.64001F, mp4a.40.2"`)
//                 resolve(sb);
//             } catch (e) {
//                 reject(e);
//             }
//         }
//         if (source.readyState === 'open') getSourceBuffer();
//         else source.addEventListener('sourceopen', getSourceBuffer);
//     });
//     sourceBuffer.mode = "sequence";

//     const urls = [
//         // "https://videos.nba.com/nba/pbp/media/2023/03/10/0022201002/655/1ceecb11-9238-e88f-82db-6e917d14e4c0_1280x720.mp4",
//         // "https://videos.nba.com/nba/pbp/media/2023/03/10/0022201002/653/cc3bd4d4-694c-6dfa-f3b6-b321afd3fceb_1280x720.mp4", 
//         "https://videos.nba.com/nba/pbp/media/2023/03/10/0022201002/651/475afe6b-8900-52d7-8d63-7c7d7f3b8d0a_1280x720.mp4"
//     ];
//     const buffers = await Promise.all(urls.map(async url => {
//         const vidBlob = await (await fetch(url)).blob();
//         return await vidBlob.arrayBuffer();
//     }));

//     sourceBuffer.onupdateend = () => {
//         console.log('updateend');
//         const top = buffers.shift();
//         console.log(top);
//         if (top !== undefined) sourceBuffer.appendBuffer(top);
//         else {
//             console.log('endofstream');
//             console.log(source.readyState + 2)
//             //source.endOfStream();
//             fetch(blobUrl).then(response => response.blob()).then(blob => {
//                 saveAs(blob, "willtest.mp4")
//             })
//         }
//     };

//     console.log(source.readyState)
//     const top = buffers.shift();
//     if (top !== undefined) sourceBuffer.appendBuffer(top);

//     source.addEventListener('sourceclose', () => console.log('sourceClose'));
//     source.addEventListener('sourceended', () => console.log('sourceended'));
//     // const asdfasdf = new MediaRecorder(source);
//     // const stream = new MediaStream();
//     // source.appendBuffer("");
// }


export default MultiDownload;