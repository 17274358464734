import { teamByIdDto, teamsDto } from "../types/dto";
import { games } from "../types/ui";
import { splitUserInput } from "./string-utils";

export const getGameText = (game: games, teamsById: teamByIdDto) => {
  const homeTeam = teamsById[game.homeTeamId].teamName;
  const awayTeam = teamsById[game.awayTeamId].teamName;
  if (game.homePts > game.awayPts)
    return `${homeTeam} ${game.homePts} v. ${game.awayPts} ${awayTeam} ${game.gameDate}`;
  else
    return `${awayTeam} ${game.awayPts} @ ${game.homePts} ${homeTeam} ${game.gameDate}`;
};

export const getGameTextNoSpoilers = (game: games, teamsById: teamByIdDto) => {
  const homeTeam = teamsById[game.homeTeamId].teamName;
  const awayTeam = teamsById[game.awayTeamId].teamName;
  return `${awayTeam} @ ${homeTeam} ${game.gameDate}`;
};

export const getHomeName = (game: games, teamsById: teamByIdDto) => {
  const homeTeam = teamsById[game.homeTeamId];
  return `${homeTeam.teamCity} ${homeTeam.teamName}`;
};

export const getAwayName = (game: games, teamsById: teamByIdDto) => {
  const awayTeam = teamsById[game.awayTeamId];
  return `${awayTeam.teamCity} ${awayTeam.teamName}`;
};

export const searchGames = (
  game: games,
  teamsById: teamByIdDto,
  query: string,
) => {
  const splitQuery = splitUserInput(query);
  return splitQuery.every((queryPart) => searchGameQueryPart(game,teamsById, queryPart));
};

export const searchGameQueryPart = (
  game: games,
  teamsById: teamByIdDto,
  queryPart: string,
) => {
  return (
    game !== null &&
    game !== undefined &&
    (getHomeName(game, teamsById).toLocaleLowerCase().includes(queryPart) ||
      getAwayName(game, teamsById).toLocaleLowerCase().includes(queryPart) ||
      game.dateSearch.toLocaleLowerCase().includes(queryPart) ||
      game.gameDate.includes(queryPart) ||
      game.homePts.toString() === queryPart ||
      game.awayPts.toString() === queryPart)
  );
}

export const getSplitQuery = (query: string) => {
  return query.split(/[\s*]+/).map((x) => x.toLocaleLowerCase());
}

export const searchTeams = (teams: teamsDto[], query: string) => {
  const splitQuery = getSplitQuery(query);
  return teams.filter((team) => {
    return splitQuery.every((queryPart) => {
      return searchTeamQueryPart(team, queryPart);
    });
  });
};

export const searchTeamQueryPart = (team: teamsDto, queryPart: string) => {
  return (
    team.teamName.toLocaleLowerCase().includes(queryPart) ||
    team.teamCity.toLocaleLowerCase().includes(queryPart) ||
    team.triCode.toLocaleLowerCase().includes(queryPart)
  );
}

export const getSortedTeams = (teams: teamsDto[]) => {
  return teams.sort((a, b) => {
    // First, compare by teamCity
    if (a.teamCity < b.teamCity) return -1;
    if (a.teamCity > b.teamCity) return 1;

    // If teamCity is the same, compare by teamName
    if (a.teamName < b.teamName) return -1;
    if (a.teamName > b.teamName) return 1;

    // If both teamCity and teamName are the same
    return 0;
  });
};
