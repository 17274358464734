import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import { useEffect, useState } from "react";

interface modalBaseProps {
    open: boolean;
    setOpen: (newVal: boolean) => void;
    content: ReactJSXElement;
    button: ReactJSXElement;
    actions: ReactJSXElement;
}
export default function ModalBase(props: modalBaseProps) {
    const [open, setOpen] = useState(props.open);
    const handleClose = () => props.setOpen(false);
    useEffect(() => {
        setOpen(props.open);
    }, [props.open])
    return (
        <>
            <Box onClick={(e) => props.setOpen(true)} display='inline-block'>
                {props.button}
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
                <DialogContent>
                    {props.content}
                </DialogContent>
                <DialogActions onClick={handleClose}>
                    {props.actions}
                </DialogActions>
            </Dialog>
        </>
    );
}
