import AddIcon from '@mui/icons-material/Add';
import FavoriteIcon from "@mui/icons-material/Favorite";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { likeNote, unlikeNote, upsertNote } from "../api/pbp-user";
import { getPlaylistDetails } from '../api/playlist';
import { MAX_POST_SIZE_PX } from '../common/css-constants';
import { useAuth } from "../context/auth-context";
import { useDataContext } from '../context/nba-data';
import { League, NotePrivacy } from "../types/constants";
import { noteDto, notesById, pbpVideoDto, playlistHeavyDto } from "../types/dto";
import { games } from '../types/ui';
import {
    createPlaylistPathExternal
} from "../utils/url-utils";
import ClipPlaylistViewWrapper from "./ClipPlaylistViewWrapper";
import GameInfo from "./GameInfo";
import NotePrivacySelect from "./NotePrivacySelect";
import NoteText from "./NoteText";
import SavePlaylistButton from "./SavePlaylistButton";
import ShareMenu from "./ShareMenu";
import VideoPager from './VideoPager';
import VideoPlayer from "./VideoPlayer";

const shouldSetVideoIndex = (index: number, pbp: any[]) => {
    if (index >= 0 && index <= pbp.length - 1) return true;
    return false;
};



interface playlistClipViewProps {
    playlist: playlistHeavyDto;
    notesById: notesById;
    pbpVideoById: { [key: number]: pbpVideoDto };
    loadPlaylistDetails: boolean;
    startingPbpVideoId: number | null;
}

export default function PlaylistClipView(props: playlistClipViewProps) {
    const {  playlist } = props;
    let startIndex = 0;
    if (props.startingPbpVideoId) {
        const i = playlist.playlistItems.findIndex(x => x.pbpVideoId === props.startingPbpVideoId);
        if (i > 0) startIndex = i;
    }
    const [videoIndex, _setVideoIndex] = useState(startIndex);
    const [videoIndexDisplay, _setVideoIndexDisplay] = useState(0);

    const [notesById, setNotesById] = useState(props.notesById);
    const [pbpVideoById, setPbpVideoById] = useState(props.pbpVideoById);
    const vid = playlist.playlistItems[videoIndex];
    const note = notesById[vid.pbpVideoId] as noteDto | undefined;
    const pbpVideo = pbpVideoById[vid.pbpVideoId] as pbpVideoDto | undefined;
    const playlistPbpVideos = playlist.playlistItems.map(x => pbpVideoById[x.pbpVideoId]).filter(x => x);
    const authContext = useAuth();

    const [noteText, _setNoteText] = useState(note?.note ?? "");
    const [notePrivacy, _setNotePrivacy] = useState((note?.notePrivacy ?? authContext.userDetails?.preferences.postPrivacy ?? NotePrivacy.Public) as NotePrivacy);
    const [isEdited, setIsEdited] = useState(false);
    const [userLiked, setUserLiked] = useState(note?.userLiked ?? false);
    const userAuth = useAuth();
    const userId = userAuth?.userId ?? null;
    const userIsAuthor = userId === playlist.userId;

    const setNoteInfo = (toSet: noteDto | undefined) => {
        _setNoteText(toSet?.note ?? "");
        _setNotePrivacy((toSet?.notePrivacy ?? NotePrivacy.Public) as NotePrivacy);
        setUserLiked((toSet?.userLiked ?? false));
    }
    const setVideoIndex = (i: number) => {
        _setVideoIndex(i);
        _setVideoIndexDisplay(i);
        const newItem = playlist.playlistItems[i];
        const note = notesById[newItem.pbpVideoId];
        setNoteInfo(note);
        setIsEdited(false);
    };

    useEffect(() => {
        if (props.loadPlaylistDetails) {
            getPlaylistDetails(props.playlist.playlistId).then(res => {
                setPbpVideoById(res.pbpVideoById);
                setNotesById(res.notesById);
                const note = res.notesById[vid.pbpVideoId] as noteDto | undefined;
                setNoteInfo(note);
            })
        }
    }, []);
    const handleVideoChange = (i: number) => {
        if (shouldSetVideoIndex(i, playlist.playlistItems)) setVideoIndex(i);
    };
    const prevVideo = () => handleVideoChange(videoIndex - 1);
    const nextVideo = () => handleVideoChange(videoIndex + 1);

    const playlistPath = createPlaylistPathExternal(
        playlist.playlistId,
        vid.pbpVideoId
    );
    const setNoteText = (newText: string) => {
        setIsEdited(true);
        _setNoteText(newText);
    };
    const setNotePrivacy = (newPrivacy: NotePrivacy) => {
        setIsEdited(true);
        _setNotePrivacy(newPrivacy);
    };

    const onSubmitNote = () => {
        if (pbpVideo) {
            upsertNote(
                userAuth.userId,
                League.NBA,
                pbpVideo.season,
                pbpVideo.gameId,
                pbpVideo.pbpVideoId,
                noteText,
                notePrivacy,
            ).then(res => {
                setIsEdited(false);
            });
        }
    };

    const onLikeClick = () => {
        if (userId && note && pbpVideo) {
            if (!userLiked) {
                likeNote(note.userId, pbpVideo.pbpVideoId, playlist.playlistId);
                setUserLiked(true);
                note.likes++;
                note.userLiked = true;
            } else {
                unlikeNote(note.userId, pbpVideo.pbpVideoId);
                setUserLiked(false);
                note.likes--;
                note.userLiked = false;
            }
        }
    };

    const userLoggedIn = userAuth.userId !== null;
    const data = useDataContext();

    let game: games | undefined;
    if (pbpVideo) {
        const allGames = data.gamesBySeason[pbpVideo.season];
        game = allGames.find((game) => game.gameId === pbpVideo.gameId);
    }

    let likesAdjust = 0;
    if (userLiked && note && userLiked !== note.userLiked) likesAdjust++;
    else if (!userLiked && note && userLiked !== note.userLiked) likesAdjust--;

    return (
        <ClipPlaylistViewWrapper
            username={playlist.username}
            ownerUserId={playlist.userId}
            userFollows={playlist.userFollows}
            userCanFollow={playlist.userCanFollow}
            itemDate={playlist.playlistUpdateUtc}
        >
            <>
                <CardContent sx={{ pb: 1, pt: 0, pl: 0, pr: 0 }}>
                    <Box marginX='10px' marginBottom='5px' textAlign='left'>
                        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                            {playlist.title}
                        </Typography>
                        <Typography variant='body2'>
                            {playlist.note}
                        </Typography>
                    </Box>

                    <VideoPlayer
                        pauseTrigger={false}
                        onEnded={nextVideo}
                        pbpVideoId={vid.pbpVideoId}
                        videoUrl={vid.url}
                        videoHeight={0}
                        onVideoLoad={() => { }}
                        onView={() => { }}
                    />
                    <Grid container marginBottom='15px'>
                        <Grid item xs={6} textAlign='center' alignSelf='center'>
                            <VideoPager isPlaylist={true} videoIndex={videoIndex} maxIndex={playlist.playlistItems.length} onVideoIndexChange={handleVideoChange} />
                        </Grid>
                        <Grid item xs={3}  paddingX='2px'>
                            <Button  sx={{ paddingY: 0 }}  onClick={prevVideo} disabled={videoIndex < 1} variant="contained" fullWidth>
                                <SkipPreviousIcon />
                                Prev
                            </Button>
                        </Grid>
                        <Grid item xs={3}  paddingX='2px'>
                            <Button  sx={{ paddingY: 0 }}  onClick={nextVideo} disabled={videoIndex >= playlist.playlistItems.length-1}  variant="contained" fullWidth>
                                Next
                                <SkipNextIcon />
                            </Button>
                        </Grid>
                    </Grid>
                    {/* <CardMedia
          autoPlay={true}
          ref={vidRef}
          controls
          muted
          playsInline={true}
          component="video"
          src={note.pbpVideo.videoUrl}
        /> */}
                    {
                        pbpVideo && <Box style={{ textAlign: "center" }}>
                            <GameInfo
                                showOverflow={true}
                                pbp={pbpVideo}
                                game={game as games}
                                showClock={true}
                                showDate={true}
                            />
                        </Box>
                    }
                    {
                        (userIsAuthor || noteText?.trim()) &&
                        <Box style={{ marginTop: '10px', textAlign: 'center' }}>
                            <NoteText
                                isPlaylist={false}
                                userLoggedIn={true}
                                userIsAuthor={userIsAuthor}
                                noteVal={noteText}
                                onChange={setNoteText}
                            />
                        </Box>

                    }
                </CardContent>
                <CardActions sx={{ fontSize: "1.2rem", padding: 0 }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container sx={{ justifyContent: "flex-end", maxWidth: MAX_POST_SIZE_PX, margin: 'auto' }} gap="15px">
                                {/* <IconButton aria-label="more" sx={{ fontSize: "1.2rem" }}>
                  <MoreHorizIcon sx={{ fontSize: "inherit" }} />
                </IconButton> */}
                                {
                                    userLoggedIn && pbpVideo && <SavePlaylistButton pbp={pbpVideo}
                                        button={
                                            <IconButton
                                                sx={{ fontSize: "1.2rem" }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        } />
                                }
                                <ShareMenu
                                        shareText={noteText}
                                        linkPath={playlistPath}
                                        pbpVideo={pbpVideo}
                                        playlistPbpVideos={playlistPbpVideos}
                                    />
                                {
                                    (userLoggedIn || note !== undefined) &&
                                    <>
                                        {
                                            note?.note &&
                                            <IconButton
                                                aria-label="Favorite"
                                                sx={{ color: userLiked ? "tomato" : "inherit" }}
                                                size="small"
                                                onClick={onLikeClick}
                                            ><FavoriteIcon sx={{ fontSize: "inherit" }} />
                                                &nbsp;{note.likes + likesAdjust}
                                            </IconButton>
                                        }
                                        {
                                            userIsAuthor &&
                                            <>
                                                <NotePrivacySelect
                                                    privacy={notePrivacy}
                                                    onChange={privacy => setNotePrivacy(privacy)}
                                                />
                                                <Button
                                                    sx={{ visibility: userIsAuthor ? "" : "hidden" }}
                                                    disabled={!isEdited}
                                                    variant="contained"
                                                    onClick={() => onSubmitNote()}
                                                >
                                                    Edit
                                                </Button>
                                            </>
                                        }
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </CardActions>
            </>
        </ClipPlaylistViewWrapper>
    );
}