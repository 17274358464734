import { feedDto, feedItem, noteAndPbpDto, playlistHeavyDto } from "../types/dto";

export const isItemPlaylist = (item: feedItem) => {
    return ('playlistId' in item);
}
export const getCombinedSortedFeed = (feed: feedDto) => {
    const combined = (feed.notes as (feedItem)[]).concat(feed.playlists);
    const toReturn = combined.sort((a, b) => {
        const firstUpdate = (isItemPlaylist(a)) ?
            (a as playlistHeavyDto).playlistUpdateUtc : a.noteUpdateUtc;
        const secondUpdate = (isItemPlaylist(b)) ?
            (b as playlistHeavyDto).playlistUpdateUtc : b.noteUpdateUtc;
        if (firstUpdate === secondUpdate) return 0;
        else return firstUpdate > secondUpdate ? -1 : 1;
    });
    return toReturn;
}