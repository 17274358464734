import { FollowFilter } from "../types/constants";
import { feedDto } from "../types/dto";
import { apiGet, apiPostJson, FEED_PREFIX } from "./utils";

export interface getFeedData {
    followFilter: FollowFilter;
    userId?: number | null | undefined;
    playerId?: number | null | undefined;
    teamId?: number | null | undefined;
    orderByTime: boolean;
    offset: number;
}

export const getFeed = async (data: getFeedData) => {
    const response = await apiPostJson(`${FEED_PREFIX}/search`, data);
    const res: feedDto = await response.json();
    return res;
};