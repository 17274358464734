import Typography from "@mui/material/Typography";
import { useDebounce } from "../utils/debounce-utils";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";

interface videoPagerProps {
    isPlaylist: boolean,
    videoIndex: number,
    maxIndex: number,
    onVideoIndexChange: (newIndex: number) => void,
}

export default function VideoPager(props: videoPagerProps) {
    const [videoIndexDisplay, _setVideoIndexDisplay] = useState(0);
    useEffect(() => {
        _setVideoIndexDisplay(props.videoIndex);
    }, [props.videoIndex])
    const debounceSetIndex = useDebounce(
        () => props.onVideoIndexChange(videoIndexDisplay),
        1000,
      );
      const onSearchChange = (newIndex: number) => {
        _setVideoIndexDisplay(newIndex);
        debounceSetIndex();
      };

      return (<Typography
        variant="body2"
        component="span"
        sx={{
          verticalAlign: "bottom",
          display: "inline-block",
          lineHeight: "1.8em",
        }}
      >
        {props.isPlaylist ? "Playlist" : "Plays"}:
        <TextField
          variant="standard"
          sx={{ width: "35px", marginLeft: "5px" }}
          inputProps={{
            style: {
              textAlign: "center",
              padding: 0,
            },
          }}
          // sx={{paddingX: '3px', width: '270px'}}
          value={videoIndexDisplay + 1}
          onChange={(e) => {
            const parsed = parseInt(e.target.value) - 1;
            if (!isNaN(parsed)) onSearchChange(parsed);
          }}
        />
        /{props.maxIndex}{" "}
      </Typography>
      )
}