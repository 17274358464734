import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import { noteAndPbpDto, pbpVideoDto } from "../types/dto";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import PlaylistItems from '../components/PlaylistItems';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { deletePlaylist } from '../api/playlist';
import EditPlaylistMetaModal from '../components/EditPlaylistMetaModal';
import Typography from '@mui/material/Typography';
import { textOverflowEllipsisSx } from '../common/css-constants';
import CreatePlaylistModal from '../components/CreatePlaylistModal';
import { capitalizeFirstLetter } from '../utils/string-utils';
import { useDataContext } from '../context/nba-data';
import Box from '@mui/material/Box';
import { getSplitQuery } from '../utils/game-utils';
import { MAX_PLAYLIST_PLAY_COUNT } from '../types/constants';
import { DateTime } from 'luxon';

interface userPlaylistsProps {
}
const menuItemPaddingX = '10px;'
const menuItemPaddingY = '3px;';
export default function UserPlaylists(props: userPlaylistsProps) {
  const [selectedPlaylistId, setSelectedPlaylistId] = useState(null as null | string);
  const [loaded, setLoaded] = useState(false);
  const [note, setNote] = useState(null as null | noteAndPbpDto);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = Number(searchParams.get("u"));
  const pbpVideoId = Number(searchParams.get("p"));

  const [userSearch, setUserSearch] = useState("");
  const auth = useAuth();

  const handleEditPlaysClick = (
    playlistId: string,
  ) => {
    setSelectedPlaylistId(playlistId)
    //navigate(createPlaylistItemsPath(playlistId));
  };


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openMenuIndex, setOpenMenuIndex] = useState<null | number>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, i: number) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(i);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null);
  };


  const onDeleteClick = async (playlistId: string) => {
    await deletePlaylist(playlistId);
    if (selectedPlaylistId === playlistId) setSelectedPlaylistId(null)
    await auth.refreshUserPlaylists();
    return true;
  }
  const data = useDataContext();

  const filteredPlaylists = useMemo(() => {
    return auth.userPlaylists.playlists?.filter(playlist => {
        if (userSearch.length < 2) {
            return true;
        } else {
            const splitQuery = getSplitQuery(userSearch.toLocaleLowerCase());
            return splitQuery.every(queryPart => {
                const additionalTerms = [] as string[];
                if (playlist.teamId) {
                    const team = data.teams[playlist.teamId];
                    additionalTerms.push(team.teamCity.toLocaleLowerCase(), team.teamName.toLocaleLowerCase(), team.triCode.toLocaleLowerCase());
                }
                if (playlist.playerId) {
                    const player = data.allPlayers.find(p => p.playerId === playlist.playerId);
                    if (player) additionalTerms.push(player.name.toLocaleLowerCase());
                }
                return playlist.title.toLocaleLowerCase().includes(queryPart) ||
                    additionalTerms.some(term => term.includes(queryPart))
            })
        }
    })

}, [userSearch, auth.userPlaylists.playlists]);



  useEffect(() => {
    auth.refreshUserPlaylists();
  }, [selectedPlaylistId]);

  const theme = useTheme();
  const greaterThanSmall = useMediaQuery(theme.breakpoints.up("sm"));

  const [openEditModalIndex, setOpenEditModalIndex] = useState<null | number>(null);

  return (
    <Grid container marginTop='10px'>
      {/* <MenuItem onClick={createAddToPlaylist} sx={{ paddingX: menuItemPaddingX, paddingY: menuItemPaddingY, borderBottom: '1px solid darkgray' }}>
        <AddIcon />&nbsp;New Playlist
    </MenuItem> */}
      {
        selectedPlaylistId === null &&
        <>
          <Grid item xs={12} textAlign='center' justifyContent='space-between'>
            <TextField
              value={userSearch}
              // onKeyDown={stopPropagation}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUserSearch(event.target.value);
              }}
              placeholder='Find playlist'
              InputProps={{
                sx: { paddingLeft: '6px' },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ paddingLeft: '0px' }} />
                  </InputAdornment>
                ),
              }}
              color='secondary'
              size='small'
              variant="outlined"
            />
            <CreatePlaylistModal />
          </Grid>
          <Grid item xs={12} textAlign='center'>
            <List color='secondary' sx={{ maxWidth: greaterThanSmall ? '600px' : '100%', margin: 'auto' }}>
              {
                filteredPlaylists.map((playlist, i) => {
                  const tags = [];
                  const team = data.teams[playlist?.teamId]?.teamName;
                  const player = data.allPlayers.find(p => p.playerId === playlist?.playerId)?.name;
                  if (team) tags.push(team);
                  if (player) tags.push(player);
                  const dt = DateTime.fromJSDate(new Date(playlist.playlistUpdateUtc));
                  const formattedTimeAgo = dt.toRelative();
                  return (
                    <ListItem key={`playlistButton${i}`}
                      color='secondary'
                      sx={{
                        paddingX: menuItemPaddingX, paddingY: menuItemPaddingY,
                        // margin: 'auto', textAlign: 'center'
                      }}>
                      <ListItemText
                        disableTypography={true}
                        primary={<Typography sx={textOverflowEllipsisSx} fontWeight='bold'>
                          {playlist.title}
                        </Typography>}
                        secondary={
                          <Box >
                            {
                              playlist.note &&
                              <Typography variant="body2" sx={textOverflowEllipsisSx}>
                                {playlist.note}
                              </Typography>
                            }
                            <Typography variant="body2" sx={{...textOverflowEllipsisSx, color: '#999999'}}>
                              {playlist.playlistItems.length}/{MAX_PLAYLIST_PLAY_COUNT} videos | {capitalizeFirstLetter(playlist.privacy)} | {formattedTimeAgo}
                            </Typography>
                            {
                              tags.length > 0 &&
                              <Typography variant="body2" sx={{...textOverflowEllipsisSx, color: '#999999'}}>
                                {'Tags: ' + tags.join(', ')}
                              </Typography>
                            }
                          </Box>
                        } />
                      <IconButton
                        aria-label="share"
                        onClick={(e) => handleMenuClick(e, i)}
                        sx={{ fontSize: "1.2rem" }}
                      >
                        <MoreHorizIcon sx={{ fontSize: "inherit" }} />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={openMenuIndex === i}
                        onClose={handleMenuClose}
                      >
                        <EditPlaylistMetaModal
                          open={openEditModalIndex === i}
                          setOpen={async (isOpen: boolean) => {
                            if (isOpen) setOpenEditModalIndex(i);
                            else {
                              handleMenuClose();
                              setOpenEditModalIndex(null);
                              await auth.refreshUserPlaylists();
                            }
                          }}
                          button={
                            <MenuItem onClick={() => {
                              setOpenEditModalIndex(i);
                            }}>
                              Edit details
                            </MenuItem>
                          }
                          playlist={playlist} />
                        <MenuItem onClick={e => {
                          handleEditPlaysClick(playlist.playlistId)
                          handleMenuClose();
                        }}
                        >
                          Edit plays
                        </MenuItem>
                        <MenuItem onClick={(e) => {
                          onDeleteClick(playlist.playlistId);
                          handleMenuClose();
                        }}>
                          Delete
                        </MenuItem>
                      </Menu>

                    </ListItem>
                  )
                })
              }
            </List>
          </Grid>
        </>
      }
      {
        selectedPlaylistId !== null &&
        <Grid item xs={12} textAlign='center'>
          <PlaylistItems playlistId={selectedPlaylistId} onBack={() => setSelectedPlaylistId(null)} />
        </Grid>
      }
    </Grid>
  )
}
