// order of operations for video events
// loaded data - has to do restart
// play (auto) - has to do start
// pause (auto) - has to do pause
// onended - should not directly tie, as doesn't fire when they hit last or next
export default class TimeTracker {
  #timespanStart: number = 0;
  #totalElapsedTime: number = 0;
  #lastCheckedTotalElapsedTime: number = 0;
  #isPlaying: boolean = false;

  start() {
    this.#timespanStart = Date.now();
    this.#isPlaying = true;
  }

  pause() {
    if (!this.#isPlaying) return;
    if (this.#timespanStart === 0) return;
    this.#totalElapsedTime += Date.now() - this.#timespanStart;
    this.#isPlaying = false;
  }

  restart() {
    this.#timespanStart = Date.now();
    this.#totalElapsedTime = 0;
    this.#lastCheckedTotalElapsedTime = 0;
    this.#isPlaying = true;
  }

  checkTime(updateLastChecked: boolean) {
    if (this.#timespanStart === 0)
      return { totalElapsedTime: 0, timeSinceLastCheck: 0 };
    const totalElapsedTime =
      this.#totalElapsedTime +
      (this.#isPlaying ? Date.now() - this.#timespanStart : 0);
    const timeSinceLastCheck =
      totalElapsedTime - this.#lastCheckedTotalElapsedTime;
    if (updateLastChecked) this.#lastCheckedTotalElapsedTime = totalElapsedTime;
    return { totalElapsedTime, timeSinceLastCheck };
  }
}

export const VIEW_TIME_MIN = 3000;
