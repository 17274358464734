import { NavigateFunction } from "react-router-dom";
import { getCurrentUser, getUserToken } from "./firebase";

export const UI_URL_PREFIX =   process.env.NODE_ENV === "development"
? "http://localhost:3000"
: "https://crowdscout.net";

export const URL_PREFIX = process.env.NODE_ENV === "development"
    ? "http://localhost:8080/api"
    : "https://api.crowdscout.net/api";
export const CDN_PREFIX = "https://data.crowdscout.net";
// TODO create function that maps current urls to base urls
export const NBA_PREFIX = `${URL_PREFIX}/nba`;
export const PBP_PREFIX = `${URL_PREFIX}/pbp`;
export const PBP_USER_PREFIX = `${URL_PREFIX}/pbpuser`;
export const SKILLS_PREFIX = `${URL_PREFIX}/skills`;
export const USER_PREFIX = `${URL_PREFIX}/user`;
export const REVIEW_PREFIX = `${URL_PREFIX}/review`;
export const AUTH_PREFIX = `${URL_PREFIX}/auth`;
export const PLAYLIST_PREFIX = `${URL_PREFIX}/playlist`;
export const FEED_PREFIX = `${URL_PREFIX}/feed`;

let globalNavigate: NavigateFunction;
let onError: (errorText: string) => void;

export function setGlobalNavigate(navigate: NavigateFunction) {
  globalNavigate = navigate;
}

export function setOnError(errFunc: (errorText: string) => void) {
  onError = errFunc;
}

const getDefaultHeaders = async () => {
  const headers = {} as { [key: string]: string };
  const firebaseToken = await getUserToken(false);
  if (firebaseToken) headers["Authorization"] = `Bearer ${firebaseToken}`;
  headers["Content-Type"] = `application/json`;
  return headers;
};

const fetchBase = async (url: RequestInfo | URL, init: RequestInit | undefined, throwAuthError: boolean) => {
  const res = await fetch(url, init);
  if (res.status === 401 && throwAuthError)
    onError("You must be logged in to perform that action");
  else if (res.status === 403 && throwAuthError)
    onError("You don't have permission to perform that action");
  else if (res.status > 399 && res.status !== 401 && res.status !== 403) {
    try {
      const resBody = await res.json();
      if (resBody?.errorText) {
        onError(resBody.errorText);
      }
    } catch (e) {
      console.error(e);
      onError("Request failed status " + res.status);
    }
  }
  return res;
}

export const apiGet = async (
  url: string,
  init?: RequestInit | undefined,
  throwAuthError = true,
) => {
  const headers = await getDefaultHeaders();
  const res = await fetchBase(url, {
    headers,
    ...init,
  }, throwAuthError);
  return res;
};

export const apiPostJson = async (
  url: string,
  data: object,
  init?: RequestInit | undefined,
  throwAuthError = true,
) => {
  const headers = await getDefaultHeaders();
  const res = await fetchBase(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
    ...init,
  }, throwAuthError);
  return res;
};

export const apiPutJson = async (
  url: string,
  data: object,
  init?: RequestInit | undefined,
  throwAuthError = true,
) => {
  const headers = { ...(await getDefaultHeaders()) };
  const res = await fetchBase(url, {
    method: "PUT",
    headers,
    body: JSON.stringify(data),
    ...init,
  }, throwAuthError);
  return res;
};



export const apiDelete = async (
  url: string,
  init?: RequestInit | undefined,
  throwAuthError = true,
) => {
  const headers = { ...(await getDefaultHeaders()) };
  const res = await fetchBase(url, {
    method: "DELETE",
    headers,
    ...init,
  }, throwAuthError);
  return res;
};
