import { useEffect, useState } from "react";
// import logo from './logo.svg';

import Close from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import { playerUI } from "../api/nba";
import { useDataContext } from "../context/nba-data";
import { League, Season } from "../types/constants";
import { games } from "../types/ui";
import {
  getGameText,
  getGameTextNoSpoilers,
  searchGames,
} from "../utils/game-utils";
import { createGameVideoUrl } from "../utils/url-utils";

interface GameMenuProps {
    selectedGames: games | games[] | null;
    maxTags: number;
    setSelectedGames: (newVal: games[]) => void;
    validGames: games[];
  }
  
 export default function GameMenu(props: GameMenuProps) {
    const data = useDataContext();
    const setSelectedGames = (game: games | games[] | null) => {
        let toReturn = [] as games[];
        if (game && Array.isArray(game)) toReturn = game;  
        else if (game) toReturn.push(game);
        props.setSelectedGames(toReturn);
    }
    return (<Autocomplete
    filterOptions={(options, { inputValue }) => {
      return options.filter((game) =>
        searchGames(game, data.teams, inputValue),
      );
    }}
    size="small"
    fullWidth
    multiple={props.maxTags > 1}
    limitTags={props.maxTags}
    sx={{
      display: "inline-block",
      verticalAlign: "top",
      flexShrink: 2,
      flexGrow: 1,
    }}
    value={props.selectedGames}
    onChange={(e, newVal) => setSelectedGames(newVal)}
    options={props.validGames}
    blurOnSelect={props.maxTags < 2}
    disableCloseOnSelect={props.maxTags > 1}
    getOptionLabel={(option) => 
      true
        ? getGameText(option, data.teams)
        : getGameTextNoSpoilers(option, data.teams)
    }
    filterSelectedOptions
    renderInput={(params) => {
      return (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          color="secondary"
          label={props.maxTags > 1 ? 'Games' : "Game"}
          placeholder={`Search for ${props.maxTags > 1 ? 'games' : "a game"}`}
        />
      );
    }}
  />)

}