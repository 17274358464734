import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Season } from "../../types/constants";
import { useState } from "react";

interface seasonSelectorProps {
  selectedSeason: Season;
  onSelectSeason: (season: Season) => void;
}

export default function SeasonSelector(props: seasonSelectorProps) {
  const seasons = Object.values(Season);

  return (
    <FormControl fullWidth>
      <InputLabel color="secondary">Season</InputLabel>
      <Select
        color="secondary"
        className="search-fields"
        size="small"
        label="Season"
        value={props.selectedSeason}
        onChange={(e) => props.onSelectSeason(e.target.value as Season)}
      >
        {seasons
          .sort((a, b) => {
            if (a < b) return 1;
            else if (a > b) return -1;
            else return 0;
          })
          .map((season, i) => {
            return (
              <MenuItem color="secondary" key={`season${i}`} value={season}>
                {season}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
