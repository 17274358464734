import ClipFeed from "../components/ClipFeed";

interface clipTimelineProps { }

export default function ClipTimeline(props: clipTimelineProps) {
  return (
    <ClipFeed
      showContentFilters={true}
      showPrivacyFilters={true}
      getNotesParams={{ orderByTime: true, offset: 0 }}
    />
  );
}
