import { useEffect, useState } from "react";
// import logo from './logo.svg';

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { playerUI } from "../../api/nba";
import { useDataContext } from "../../context/nba-data";

const all = "All";
const allO = "All Offense";
const allD = "All Defense";

interface playersSelectorProps {
  showAbbrs: boolean;
  players: playerUI[];
  selectedPlayers: playerUI[];
  label: string;
  maxItems: number;
  onChange: (players: playerUI[]) => void;
}

export default function PlayersSelector(props: playersSelectorProps) {
  // const [selectedPlayers, _setSelectedPlayers] = useState<playerUI[]>([]);
  // const setSelectedPlayers = (players: playerUI[]) => {
  //     _setSelectedPlayers(players);
  //     props.onChange(players);
  // }

  const data = useDataContext();
  // const shotTypesAndEVAT = data.shotTypes;
  // const shotTypes = [all, ...Object.keys(shotTypesAndEVAT)];
  // const [selectedShotTypes, setSelectedShotTypes] = useState(shotTypes);
  // const [shotTypeSearch, setShotTypeSearch] = useState('');

  const [comparer, setComparer] = useState("gte");
  const [comparerValue, setComparerValue] = useState(0);

  return (
    <>
      <Autocomplete
        filterOptions={(options, { inputValue }) => {
          return options.filter(
            (player) =>
              player.name.toLocaleLowerCase().includes(inputValue) ||
              player.teamAbbrs.toLocaleLowerCase().includes(inputValue) ||
              player.teamNames.toLocaleLowerCase().includes(inputValue),
          );
        }}
        fullWidth
        size="small"
        limitTags={1}
        multiple={true}
        value={props.selectedPlayers}
        onChange={(e, newVal) => {
          if (newVal.length <= props.maxItems) props.onChange(newVal);
          else if (props.maxItems === 1) props.onChange([newVal[1]]);
        }}
        options={props.players}
        getOptionLabel={(player) =>
          `${player.name}` + (!props.showAbbrs ? "" : ` (${player.teamAbbrs})`)
        }
        filterSelectedOptions
        renderInput={(params) => {
          return (
            <TextField
              color="secondary"
              {...params}
              InputLabelProps={{ shrink: true }}
              label={props.label}
            />
          );
        }}
      />
    </>
  );
}
