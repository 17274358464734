import AddIcon from '@mui/icons-material/Add';
import FavoriteIcon from "@mui/icons-material/Favorite";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { likeNote, unlikeNote, upsertNote } from "../api/pbp-user";
import { useAuth } from "../context/auth-context";
import { useDataContext } from "../context/nba-data";
import { League, NotePrivacy } from "../types/constants";
import { noteAndPbpDto, noteDto, pbpVideoDto } from "../types/dto";
import { games } from "../types/ui";
import {
  createClipPathExternal
} from "../utils/url-utils";
import ClipPlaylistViewWrapper from "./ClipPlaylistViewWrapper";
import GameInfo from "./GameInfo";
import NotePrivacySelect from "./NotePrivacySelect";
import NoteText from "./NoteText";
import SavePlaylistButton from "./SavePlaylistButton";
import ShareMenu from "./ShareMenu";
import VideoPlayer from "./VideoPlayer";
import Box from '@mui/material/Box';
import { MAX_POST_SIZE_PX } from '../common/css-constants';
interface clipViewProps {
  note: noteAndPbpDto | noteDto;
  pbpVideo: pbpVideoDto;
}
export default function ClipView(props: clipViewProps) {
  const note = props.note;
  const pbpVideo = props.pbpVideo;
  const [noteText, _setNoteText] = useState(note.note);
  const [notePrivacy, _setNotePrivacy] = useState(note.notePrivacy as NotePrivacy);
  const [isEdited, setIsEdited] = useState(false);
  const [userLiked, setUserLiked] = useState(note.userLiked);
  const userAuth = useAuth();
  const userId = userAuth?.userId ?? null;
  const userIsAuthor = userId === note.userId;


  const clipPath = createClipPathExternal(
    note.userId,
    pbpVideo.pbpVideoId,
  );
  const setNoteText = (newText: string) => {
    setIsEdited(true);
    _setNoteText(newText);
  };
  const setNotePrivacy = (newPrivacy: NotePrivacy) => {
    setIsEdited(true);
    _setNotePrivacy(newPrivacy);
  };

  const onSubmitNote = () => {
    upsertNote(
      userAuth.userId,
      League.NBA,
      note.seasonYear,
      pbpVideo.gameId,
      pbpVideo.pbpVideoId,
      noteText,
      notePrivacy,
    ).then(res => {
      setIsEdited(false);
    });
  };

  const onLikeClick = () => {
    if (userId) {
      if (!userLiked) {
        likeNote(note.userId, pbpVideo.pbpVideoId, null);
        setUserLiked(true);
      } else {
        unlikeNote(note.userId, pbpVideo.pbpVideoId);
        setUserLiked(false);
      }
    }
  };

  const userLoggedIn = userAuth.userId !== null;
  const data = useDataContext();

  const allGames = data.gamesBySeason[note.seasonYear];
  const game = allGames.find((game) => game.gameId === pbpVideo.gameId);

  let likesAdjust = 0;
  if (userLiked && userLiked !== note.userLiked) likesAdjust++;
  else if (!userLiked && userLiked !== note.userLiked) likesAdjust--;
  return (
    <ClipPlaylistViewWrapper
      username={note.username}
      ownerUserId={note.userId}
      userFollows={note.userFollows}
      userCanFollow={note.userCanFollow}
      itemDate={note.noteUpdateUtc}
    >
      <>
        <CardContent sx={{ pb: 1, pt: 0, pl: 0, pr: 0 }}>
          <VideoPlayer
            pauseTrigger={false}
            onEnded={() => { }}
            pbpVideoId={pbpVideo.pbpVideoId}
            videoUrl={pbpVideo.videoUrl}
            videoHeight={0}
            onVideoLoad={() => { }}
            onView={() => { }}
          />
          {/* <CardMedia
        autoPlay={true}
        ref={vidRef}
        controls
        muted
        playsInline={true}
        component="video"
        src={note.pbpVideo.videoUrl}
      /> */}
          <Box style={{ textAlign: "center" }}>
            <GameInfo
              showOverflow={true}
              pbp={pbpVideo}
              game={game as games}
              showClock={true}
              showDate={true}
            />
          </Box>
          {
            (userIsAuthor || noteText?.trim()) && <Box style={{ textAlign: "center", marginTop: '10px' }}>
              <NoteText
                isPlaylist={false}
                userLoggedIn={true}
                userIsAuthor={userIsAuthor}
                noteVal={noteText}
                onChange={setNoteText}
              />
            </Box>
          }
        </CardContent>
        <CardActions sx={{ fontSize: "1.2rem", padding: 0, maxWidth: MAX_POST_SIZE_PX, margin: 'auto' }}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container sx={{ justifyContent: "flex-end" }} gap="15px">
                {/* <IconButton aria-label="more" sx={{ fontSize: "1.2rem" }}>
                <MoreHorizIcon sx={{ fontSize: "inherit" }} />
              </IconButton> */}
                {
                  userLoggedIn && pbpVideo && <SavePlaylistButton pbp={pbpVideo}
                    button={
                      <IconButton
                        sx={{ fontSize: "1.2rem" }}
                      >
                        <AddIcon />
                      </IconButton>
                    } />
                }
                <ShareMenu
                  shareText={noteText}
                  linkPath={clipPath}
                  pbpVideo={pbpVideo}
                  playlistPbpVideos={[]}
                />
                <IconButton
                  aria-label="Favorite"
                  sx={{ color: userLiked ? "tomato" : "inherit" }}
                  size="small"
                  onClick={onLikeClick}
                >
                  <FavoriteIcon sx={{ fontSize: "inherit" }} />
                  &nbsp;{note.likes + likesAdjust}
                </IconButton>
                {
                  userIsAuthor &&
                  <>
                    <NotePrivacySelect
                      privacy={notePrivacy}
                      onChange={privacy => setNotePrivacy(privacy)}
                    />
                    <Button
                      sx={{ visibility: userIsAuthor ? "" : "hidden" }}
                      disabled={!isEdited}
                      variant="contained"
                      onClick={() => onSubmitNote()}
                    >
                      Edit
                    </Button>
                  </>
                }
              </Grid>
            </Grid>
          </Grid>
        </CardActions>
      </>
    </ClipPlaylistViewWrapper>
  );
}
