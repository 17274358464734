import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { getAccountAuth } from "../api/firebase";
import { NotePrivacies, NotePrivacy } from "../types/constants";
import { AccountLevel, accountAuthorizationsByLevel } from "../types/user-authorization";
interface notePrivacySelectProps {
    privacy: NotePrivacy;
    onChange: (privacy: NotePrivacy) => void;
}
export default function NotePrivacySelect(props: notePrivacySelectProps) {
    const [accountAuth, setAccountAuth] = useState(accountAuthorizationsByLevel[AccountLevel.BASIC]);
    useEffect(() => {
      getAccountAuth().then(accLvl => {
        setAccountAuth(accLvl);
      });
    }, []);
    
    const setNotePrivacy = (privacy: NotePrivacy) => {
        props.onChange(privacy);
    }
    return <FormControl>
    <InputLabel color="secondary">Privacy</InputLabel>
    <Select
      color="secondary"
      className="search-fields"
      size="small"
      label="Privacy"
      value={props.privacy}
      onChange={(e) => setNotePrivacy(e.target.value as NotePrivacy)}
    >
      {NotePrivacies.map((np) => (
        <MenuItem
          color="secondary"
          key={np}
          value={np}
          // disabled if it's not public, and you can't private post
          disabled={np !== NotePrivacy.Public && !accountAuth.canPrivacySettings}
        >
          {np.charAt(0).toUpperCase() + np.slice(1)}
        </MenuItem>))}
    </Select>
  </FormControl>
}