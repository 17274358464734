import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Comparers, comparers } from "../../types/constants";
import { Container } from "@mui/material";

interface quarterTimeSelectorProps {
  comparer: Comparers;
  minute: number;
  second: number;
  onChange: (comparer: Comparers, minute: number, second: number) => void;
}

export default function QuarterTimeSelector(props: quarterTimeSelectorProps) {
  return (
    <div style={{ display: "inline-block" }}>
      <TextField
        select
        size="small"
        label="Clock"
        sx={{ width: "70px" }}
        value={props.comparer}
        color="secondary"
        onChange={(e) =>
          props.onChange(
            e.target.value as Comparers,
            props.minute,
            props.second,
          )
        }
      >
        {comparers.map((comparer) => (
          <MenuItem key={`comparer${comparer.value}`} value={comparer.value}>
            {comparer.text}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        sx={{ width: "60px" }}
        color="secondary"
        size="small"
        id="outlined-number"
        label="Min"
        type="number"
        value={props.minute}
        onChange={(e) => {
          const num = parseInt(e.target.value);
          if (num >= 0 && num <= 12)
            props.onChange(props.comparer, props.minute, num);
          else if (isNaN(num)) props.onChange(props.comparer, props.minute, 0);
        }}
        InputProps={{ inputProps: { min: 0, max: 12 } }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      :
      <TextField
        sx={{ width: "60px" }}
        color="secondary"
        size="small"
        id="outlined-number"
        label="Sec"
        type="number"
        value={props.second}
        onChange={(e) => {
          const num = parseInt(e.target.value);
          if (num >= 0 && num <= 59)
            props.onChange(props.comparer, props.minute, num);
          else if (isNaN(num)) props.onChange(props.comparer, props.minute, 0);
        }}
        InputProps={{ inputProps: { min: 0, max: 59 } }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>
  );
}
