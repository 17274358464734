import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

interface privacyPolicyProps {}

export default function PrivacyPolicy(props: privacyPolicyProps) {
  return (
    <Container>
      <Typography variant="body1" whiteSpace="pre-line">
        {`
        **Privacy Policy for Crowd Scout**

**Last updated: [Current Date]**

Thank you for using Crowd Scout! Your privacy is important to us. This Privacy Policy outlines how Crowd Scout collects, uses, maintains, and discloses information collected from users (referred to as "you" or "your") of the Crowd Scout social media platform (referred to as the "Service").

**1. Information We Collect**

**Personal Information:**
- When you register for an account, we may collect personal information such as your name, email address, username, and password.
- We may collect additional personal information when you choose to provide it, such as your profile picture, gender, date of birth, and other optional profile details.
  
**Usage Information:**
- We automatically collect usage information when you interact with the Service, including your IP address, device information, browser type, pages visited, and actions taken.

**Content:**
- Any content you post or share on Crowd Scout, including text, photos, videos, and comments, may be collected and stored by us.

**2. How We Use Information**

**Personal Information:**
- We use your personal information to create and manage your account, communicate with you, and provide customer support.
- Your email address may be used to send you important notifications, updates, and newsletters related to the Service.
- We may use your personal information to personalize your experience on Crowd Scout and to provide relevant content and recommendations.

**Usage Information:**
- We use usage information to analyze trends, administer the Service, improve our platform, and gather demographic information about our user base.
- This information helps us understand how users interact with the Service and allows us to optimize the user experience.

**Content:**
- Your content may be displayed on Crowd Scout and shared with other users according to your privacy settings.
- We may use your content for promotional purposes, such as featuring it on our website or social media channels, with your consent.

**3. How We Protect Your Information**

- We implement security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
- Your account information is protected by a password for your privacy and security.
- We use encryption technology to safeguard sensitive data transmitted between your device and our servers.

**4. Sharing Your Information**

- We may share your personal information with third-party service providers who assist us in operating the Service, conducting business, or servicing you.
- We may disclose your information in response to legal requests, court orders, or government regulations, or to protect the rights, property, or safety of Crowd Scout, our users, or others.
- Your content may be shared with other users according to your privacy settings and the features of the Service.

**5. Your Choices**

- You can control and manage your personal information and privacy settings within your Crowd Scout account.
- You can choose whether to share certain information publicly or with specific users.
- You can opt out of receiving promotional emails or newsletters by following the instructions in the email or contacting us directly.

**6. Children's Privacy**

- Crowd Scout is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13 years of age. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us to request deletion of the information.

**7. Changes to This Privacy Policy**

- We reserve the right to update or revise this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy on the Crowd Scout website.
- We encourage you to periodically review this Privacy Policy to stay informed about how we are protecting your information.

**8. Contact Us**

- If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at crowdscoutops@gmail.com

By using Crowd Scout, you consent to the collection and use of your information as described in this Privacy Policy.
`}
      </Typography>
    </Container>
  );
}
