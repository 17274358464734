import JSZip from "jszip";
import { pbpVideoDto } from "../types/dto";
import saveAs from "file-saver";

export const renderFileNameFromPbpVideo = (pbpVideo: pbpVideoDto, awayTeam: string, homeTeam: string) => {
    //return `${pbpVideo.gameDate.replaceAll('/', '-')}_${pbpVideo.period}Q_${pbpVideo.gameClock.replace(':', '\uA789')}`;
    return `${awayTeam.toUpperCase()} @ ${homeTeam.toUpperCase()} ${pbpVideo.gameDate.replaceAll('/', '-')} ${pbpVideo.period}Q ${pbpVideo.gameClock.replace(':', '\uA789')}`;
}

export const maxDownloadBatchSize = 100;
export const  zipAndDownload = async (pbpVideo: pbpVideoDto[], awayTeams: string[], homeTeams: string[], startIndex: number) => {
    const urls = pbpVideo.map(x => x.videoUrl);
    const failedUrls : string[] = [];
    const blobs = await Promise.all(urls.map(async url => {
        try {
            const mp4 = await fetch(url);
            return await mp4.blob();    
        } catch (e) {
            console.error(e);
            failedUrls.push(url);
        }
    }));
    if (failedUrls.length > 0) alert('Failed to download the following videos. You must manually download: ' + failedUrls.join('\r\n'));
    const zip = new JSZip();
    blobs.forEach((blob, i) => {
        if (blob !== null && blob !== undefined)
            zip.file(`${renderFileNameFromPbpVideo(pbpVideo[i], awayTeams[i], homeTeams[i])}.mp4`, blob, {binary: true});
    });
    zip.generateAsync({type:"blob"})
        .then(function (blob) {
            saveAs(blob, `Basketball videos ${startIndex+1}-${startIndex+blobs.length}.zip`);
        });
}


export const forceDownload = (blob: string, filename: string) => {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
}

// Current blob size limit is around 500MB for browsers
export const downloadResource = ( pbpVideo: pbpVideoDto, awayTeam: string, homeTeam: string) => {
    const filename = renderFileNameFromPbpVideo(pbpVideo, awayTeam, homeTeam);
    fetch(pbpVideo.videoUrl, {
        headers: new Headers({
            // eslint-disable-next-line no-restricted-globals
            'Origin': location.origin
        }),
        mode: 'cors'
    })
        .then(response => response.blob())
        .then(blob => {
            let blobUrl = window.URL.createObjectURL(blob);
            forceDownload(blobUrl, filename);
        })
        .catch(e => console.error(e));
}