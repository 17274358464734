import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

interface termsAndConditionsProps {}

export default function TermsAndConditions(props: termsAndConditionsProps) {
  return (
    <Container>
      <Typography variant="body1" whiteSpace="pre-line">
        {`
                **Terms and Conditions for Crowd Scout**

                Kindly review these Terms and Conditions ("Terms", "Terms and Conditions") thoroughly before utilizing the Crowd Scout website (referred to as the "Service") operated by Crowd Scout ("us", "we", or "our").
                Your access to and utilization of the Service are contingent upon your acceptance of and adherence to these Terms. These Terms are applicable to all visitors, users, and others who either access or use the Service.By accessing or using the Service, you agree to be bound by these Terms. Should you disagree with any aspect of the terms, you are advised not to access the Service.
    
                **Content**
                Our Service allows you to contribute, link, share, and otherwise make available specific information, text, graphics, videos, or other material ("Content"). You are accountable for the Content you post to the Service, including its legality, reliability, and appropriateness.
    
                By posting Content to the Service, you grant us the right and license to utilize, modify, publicly perform, publicly display, reproduce, and distribute such Content through the Service. You retain all rights to any Content you submit, post, or display on or through the Service, and you are responsible for protecting those rights. You acknowledge that this license includes the right for us to make your Content accessible to other Service users, who may also utilize your Content in accordance with these Terms.
    
                You confirm and warrant that: (i) the Content belongs to you (you own it) or you possess the right to use it and grant us the rights and license as stipulated in these Terms, and (ii) the posting of your Content on or through the Service does not infringe upon the privacy rights, publicity rights, copyrights, contract rights, or any other rights of any individual.
    
                **Copyright Policy**
                We uphold respect for the intellectual property rights of others. Our policy is to respond to any claim that Content posted on the Service violates the copyright or other intellectual property rights ("Infringement") of any individual.
    
                If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been replicated in a manner constituting copyright infringement through the Service, you must submit a written notice to [Crowd Scout's contact email] detailing the alleged Infringement. You may be liable for damages for misrepresenting that any Content is infringing your copyright.
    
                **Links To Other Web Sites**
                Crowd Scout exercises no control over, and accepts no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Crowd Scout shall not be held responsible or liable, directly or indirectly, for any damage or loss caused or claimed to be caused by or in connection with the use of or reliance on any such content, goods, or services available through any such websites or services.
    
                **Termination**
                We reserve the right to immediately terminate or suspend your account, without prior notice or liability, for any reason whatsoever, including, but not limited to, breach of the Terms. Upon termination, your right to use the Service will cease immediately. If you wish to terminate your account, you may simply discontinue using the Service.
    
                **Limitation Of Liability**
                Under no circumstances shall Crowd Scout, its directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
    
                **Disclaimer**
                Your utilization of the Service is solely at your own risk. The Service is provided on an "as is" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.
    
                Crowd Scout, its subsidiaries, affiliates, and licensors do not guarantee that (a) the Service will function uninterrupted, secure, or available at any particular time or location; (b) any errors or defects will be corrected; (c) the Service is free of viruses or other harmful components; or (d) the results of using the Service will meet your requirements.
    
                **Governing Law**
                These Terms shall be interpreted and governed in accordance with the laws of California, without regard to its conflict of law provisions.
    
                Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.
    
                **Changes**
                We retain the right, at our sole discretion, to modify or replace these Terms at any time. In the event of a material revision, we will endeavor to provide at least 15 days' notice before any new terms take effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please discontinue using the Service.
    
                **Contact Us**
                Should you have any questions about these Terms, please reach out to us at crowdscoutops@gmail.com    
                `}
      </Typography>
    </Container>
  );
}
