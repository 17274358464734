import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { upsertNote } from "../api/pbp-user";
import { deletePlay } from '../api/playlist';
import { useAuth } from "../context/auth-context";
import { League, NotePrivacy } from "../types/constants";
import { noteDto, pbpVideoDto } from "../types/dto";
import { games } from '../types/ui';
import GameInfo from './GameInfo';
import NotePrivacySelect from "./NotePrivacySelect";
import NoteText from "./NoteText";
import SavePlaylistButton from "./SavePlaylistButton";
import VideoPlayer from './VideoPlayer';
import { MAX_POST_SIZE_PX } from '../common/css-constants';

interface playlistItemViewProps {
    playlistId: string;
    pbpVideo: pbpVideoDto;
    note: noteDto | undefined;
    curGame: games;
    onDelete: () => void;
}

export default function PlaylistItemView(props: playlistItemViewProps) {
    const { pbpVideo, curGame, playlistId } = props;
    const [isEdited, setIsEdited] = useState(false);

    const [note, _setNote] = useState(props.note?.note ?? "");
    
    const authContext = useAuth();
    const [notePrivacy, _setNotePrivacy] = useState(props.note?.notePrivacy ?? authContext.userDetails?.preferences.postPrivacy ?? NotePrivacy.Public);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const setNote = (note: string) => {
        _setNote(note);
        setIsEdited(true);
    }
    const setNotePrivacy = (privacy: NotePrivacy) => {
        _setNotePrivacy(privacy);
        setIsEdited(true);
    }

    const onDeleteClick = async (event: React.MouseEvent<HTMLLIElement>, playlistId: string, pbpVideoId: number) => {
        event.stopPropagation();
        handleMenuClose();
        await deletePlay(playlistId, pbpVideoId);
        await props.onDelete();// getSetPlaylistDetails();
        return true;
    }
    const maxWidth = '100%';
    const width = '100%';// (1280 + 32) + "px";
    const textAlign = 'center';
    const margin = 'auto';

    const userAuth = useAuth();
    const onSubmitNote = () => {
        upsertNote(
          userAuth.userId,
          League.NBA,
          pbpVideo.season,
          pbpVideo.gameId,
          pbpVideo.pbpVideoId,
          note,
          notePrivacy,
        ).then((x) => {
            setIsEdited(false);
        });
      };

      
    return <Accordion sx={{ margin }}>
        <AccordionSummary
            sx={{
                maxWidth, width,
                textAlign,
                flexDirection: 'row-reverse',
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                    transform: 'rotate(90deg)',
                },
                '& .MuiAccordionSummary-content': {
                    maxWidth,
                    marginLeft: '7px',
                },
            }}
            expandIcon={<ArrowForwardIosSharpIcon
                sx={{
                    fontSize: '0.9rem',
                }} />}>
            <IconButton
                aria-label="share"
                onClick={(e) => handleMenuClick(e)}
                sx={{ fontSize: "1.2rem" }}
            >
                <MoreHorizIcon sx={{ fontSize: "inherit" }} />
            </IconButton>
            <div style={{ display: 'inline-block', flexGrow: 1, minWidth: 0 }}>
                <GameInfo
                    pbp={pbpVideo}
                    game={curGame}
                    showClock={true}
                    showDate={false}
                    showOverflow={false}
                />
                <Typography
                    variant="body2"
                    noWrap
                    sx={{
                        maxWidth,
                        marginTop: '8px',
                        fontSize: { xs: "0.8rem", sm: "0.875rem", md: "0.95rem" },
                        // textAlign: { xs: "center", sm: "left" },
                        pl: { xs: 0, sm: "10px" },
                    }}
                >
                    {pbpVideo.playDesc}
                </Typography>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={anchorEl !== null}
                onClick={e => e.stopPropagation()}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <SavePlaylistButton pbp={pbpVideo}
                    onClose={() => handleMenuClose()}
                    button={
                        <MenuItem disabled={false}>Add to playlist</MenuItem>
                    } />
                <MenuItem onClick={(event) => {
                    onDeleteClick(event, playlistId, pbpVideo.pbpVideoId);
                    handleMenuClose();
                }}>
                    Delete
                </MenuItem>
            </Menu>
        </AccordionSummary>
        <AccordionDetails sx={{ maxWidth, width, textAlign, margin }}>

            <VideoPlayer
                pauseTrigger={false}
                onEnded={() => { }}
                pbpVideoId={pbpVideo.pbpVideoId}
                videoUrl={pbpVideo.videoUrl}
                videoHeight={0}
                onVideoLoad={() => { }}
                onView={() => { }}
            />
            <div style={{ display: 'inline-block', flexGrow: 1, minWidth: 0 }}>
                <GameInfo
                    pbp={pbpVideo}
                    game={curGame}
                    showClock={true}
                    showDate={false}
                    showOverflow={false}
                />
                <Typography
                    variant="body2"
                    noWrap
                    sx={{
                        maxWidth,
                        marginTop: '8px',
                        fontSize: { xs: "0.8rem", sm: "0.875rem", md: "0.95rem" },
                        // textAlign: { xs: "center", sm: "left" },
                        pl: { xs: 0, sm: "10px" },
                    }}
                >
                    {pbpVideo.playDesc}
                </Typography>
            </div>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <NoteText
                        isPlaylist={false}
                        userLoggedIn={true}
                        userIsAuthor={true}
                        noteVal={note}
                        onChange={setNote} />
                </Grid>
                <Grid item width='100%' maxWidth={MAX_POST_SIZE_PX} margin='auto' textAlign='right' >
                    <NotePrivacySelect
                        privacy={notePrivacy as NotePrivacy}
                        onChange={privacy => setNotePrivacy(privacy)}
                    />
                    <Button
                    sx={{marginLeft: '10px', height: '100%'}}
                        disabled={!isEdited}
                        variant="contained"
                        onClick={() => onSubmitNote()}
                    >
                        {props.note ? 'Edit' : 'Add'}
                    </Button>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}
