import { useEffect, useState } from "react";
// import logo from './logo.svg';

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { playerUI } from "../../api/nba";
import { useDataContext } from "../../context/nba-data";
import { pbpVideoDto } from "../../types/dto";
import {
  playerAssistedShot,
  playerBlockedShot,
  playerCommittedFoul,
  playerCommittedTurnover,
  playerDefMadeShotQueryFiltered,
  playerDefMissedShotQueryFiltered,
  playerDrewFTs,
  playerForcedTurnover,
  playerIsDefReb,
  playerMadeShotQueryFiltered,
  playerMissedShotQueryFiltered,
  playerisOffReb,
} from "../../utils/pbp-filter";
import PlayersSelector from "./PlayersSelector";
import Grid from "@mui/material/Grid";
import { EventMsgType } from "../../types/event";
import ShotTypeFilter from "./ShotTypeFilter";

const all = "All";
const allO = "All Offense";
const allD = "All Defense";

const offenseFiltersData = [
  {
    displayName: allO,
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) => false,
  },
  {
    displayName: "2P Make Unassisted",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerMadeShotQueryFiltered(playerId, false, false, [], pbp),
  },
  {
    displayName: "2P Make Assisted",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerMadeShotQueryFiltered(playerId, true, false, [], pbp),
  },
  {
    displayName: "3P Make Unassisted",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerMadeShotQueryFiltered(playerId, false, true, [], pbp),
  },
  {
    displayName: "3P Make Assisted",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerMadeShotQueryFiltered(playerId, true, true, [], pbp),
  },
  {
    displayName: "Assist",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerAssistedShot(playerId, pbp),
  },
  {
    displayName: "FT Drawn",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerDrewFTs(playerId, pbp),
  },
  {
    displayName: "2P Miss",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerMissedShotQueryFiltered(playerId, false, [], pbp),
  },
  {
    displayName: "3P Miss",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerMissedShotQueryFiltered(playerId, true, [], pbp),
  },
  {
    displayName: "Turnover",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerCommittedTurnover(playerId, pbp),
  },
  {
    displayName: "Offensive Rebound",
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerisOffReb(playerId, pbp),
  },
];
const defenseFiltersData = [
  {
    displayName: allD,
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) => false,
  },
  {
    displayName: "Defended 2P Miss",
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerDefMissedShotQueryFiltered(playerId, false, [], pbp),
  },
  {
    displayName: "Defended 3P Miss",
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerDefMissedShotQueryFiltered(playerId, true, [], pbp),
  },
  {
    displayName: "TO Forced",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerForcedTurnover(playerId, pbp),
  },
  {
    displayName: "Block",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerBlockedShot(playerId, pbp),
  },
  {
    displayName: "Defended 2P Make Unassisted",
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerDefMadeShotQueryFiltered(playerId, false, false, [], pbp),
  },
  {
    displayName: "Defended 2P Make Assisted",
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerDefMadeShotQueryFiltered(playerId, true, false, [], pbp),
  },
  {
    displayName: "Defended 3P Make Unassisted",
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerDefMadeShotQueryFiltered(playerId, false, true, [], pbp),
  },
  {
    displayName: "Defended 3P Make Assisted",
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerDefMadeShotQueryFiltered(playerId, true, true, [], pbp),
  },
  {
    displayName: "Foul",
    isIncluded: true,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerCommittedFoul(playerId, pbp),
  },
  {
    displayName: "Defensive Rebound",
    isIncluded: false,
    filter: (playerId: number | null, pbp: pbpVideoDto) =>
      playerIsDefReb(playerId, pbp),
  },
];

const offenseFiltersLookup = new Set(
  offenseFiltersData.map((x) => x.displayName),
);
const defenseFiltersLookup = new Set(
  defenseFiltersData.map((x) => x.displayName),
);
interface playersSelectorProps {
  players: playerUI[];
  onChange: (newFunc: (pbp: pbpVideoDto) => boolean) => void;
}

export default function PlayFilter(props: playersSelectorProps) {
  const [selectedPlayer, setSelectedPlayer] = useState<playerUI[]>([]);
  const [shotTypeInput, setShotTypeInput] = useState('');
  // const [selectedPlayers, _setSelectedPlayers] = useState<playerUI[]>([]);
  // const setSelectedPlayers = (players: playerUI[]) => {
  //     _setSelectedPlayers(players);
  //     props.onChange(players);
  // }
  const allPlayTypes = offenseFiltersData
    .concat(defenseFiltersData)
    .sort((a, b) => {
      let returnVal = 0;
      if (a.displayName === allO || a.displayName === allD) returnVal--;
      if (b.displayName === allO || b.displayName === allD) returnVal++;
      return returnVal;
    });
  const initialPlayTypes = allPlayTypes.filter((x) => x.isIncluded);
  const [selectedPlayTypes, setSelectedPlayTypes] = useState(
    allPlayTypes.filter((x) => false),
  );

  const data = useDataContext();
  const shotTypesAndEVAT = data.shotTypes;
  const shotTypes = Object.keys(shotTypesAndEVAT);
  const [selectedShotTypes, setSelectedShotTypes] = useState([] as string[]);
  const [shotTypeSearch, setShotTypeSearch] = useState("");

  useEffect(() => {
    const shotEVATs = new Set<number>();
    selectedShotTypes.forEach((st) => {
      shotTypesAndEVAT[st].forEach((evat) => shotEVATs.add(evat));
    });

    const filterShotType = (pbp: pbpVideoDto) => {
      if (selectedShotTypes.length === 0) return true;
      else
        return (
          (pbp.eventMsgType !== EventMsgType.MadeShots &&
            pbp.eventMsgType !== EventMsgType.MissedShots) ||
          ((pbp.eventMsgType === EventMsgType.MadeShots ||
            pbp.eventMsgType === EventMsgType.MissedShots) &&
            shotEVATs.has(pbp.eventMsgActionType))
        );
    };
    if (selectedPlayTypes.length === 0) {
      if (selectedPlayer.length === 0)
        props.onChange(filterShotType);
      else {
        const playerId = selectedPlayer[0].playerId;
        props.onChange(
          (pbp: pbpVideoDto) =>
            filterShotType(pbp) &&
            (pbp.player1Id === playerId ||
              pbp.player2Id === playerId ||
              pbp.player3Id === playerId ||
              pbp.defPlayerId === playerId ||
              pbp.rebPlayerId === playerId),
        );
      }
    } else {
      props.onChange(
        (pbp: pbpVideoDto) =>
          filterShotType(pbp) &&
          selectedPlayTypes.some((x) =>
            x.filter(selectedPlayer[0]?.playerId ?? null, pbp),
          ),
      );
    }
    // const shotFilters = anyShotFilters && selectedShotTypes.length !== shotTypes.length ? selectedShotTypes.filter(shotType => shotTypesAndEVAT[shotType]).flatMap(shotType => shotTypesAndEVAT[shotType]) : [];
    // const query: PbpQueryUtils.pbpQueryUI = PbpQueryUtils.createPlayerPlaysQuery(selectedPlayers?.id, filtersJson.join(), shotFilters.join(), comparerValue, comparer);
    // props.setQuery({
    //     ...query, isValid: filtersJson.length > 0 && (props.playerOptional || selectedPlayers !== null),
    //     isAnd: true, id: 0 // these don't matter, get overwritten by containers
    // });
  }, [selectedPlayer, selectedPlayTypes, selectedShotTypes]);

  return (
    <>
      <Grid item xs={6}>
        <PlayersSelector
          showAbbrs={true}
          players={props.players}
          selectedPlayers={selectedPlayer}
          maxItems={1}
          label="Player"
          onChange={setSelectedPlayer}
        />
      </Grid>

      <Grid item xs={6}>
        <Autocomplete
          color="secondary"
          disableCloseOnSelect
          fullWidth
          size="small"
          limitTags={1}
          multiple
          value={selectedPlayTypes}
          onChange={(e, newVal) => {
            // if they selected all
            if (
              selectedPlayTypes.every((type) => type.displayName !== allO) &&
              newVal.some((type) => type.displayName === allO)
            )
              setSelectedPlayTypes(
                offenseFiltersData.concat(
                  newVal.filter(
                    (offFilter) =>
                      !offenseFiltersLookup.has(offFilter.displayName),
                  ),
                ),
              );
            else if (
              selectedPlayTypes.every((type) => type.displayName !== allD) &&
              newVal.some((type) => type.displayName === allD)
            )
              setSelectedPlayTypes(
                newVal
                  .filter(
                    (defFilter) =>
                      !defenseFiltersLookup.has(defFilter.displayName),
                  )
                  .concat(defenseFiltersData),
              );
            // if they unselected all
            else if (
              selectedPlayTypes.some((type) => type.displayName === allO) &&
              newVal.every((type) => type.displayName !== allO)
            )
              setSelectedPlayTypes(
                newVal.filter((x) => !offenseFiltersLookup.has(x.displayName)),
              );
            else if (
              selectedPlayTypes.some((type) => type.displayName === allD) &&
              newVal.every((type) => type.displayName !== allD)
            )
              setSelectedPlayTypes(
                newVal.filter((x) => !defenseFiltersLookup.has(x.displayName)),
              );
            else setSelectedPlayTypes(newVal);
          }}
          options={allPlayTypes}
          getOptionLabel={(playType) => playType.displayName}
          filterSelectedOptions
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                color="secondary"
                InputLabelProps={{ shrink: true }}
                label="Play type"
                // placeholder="Favorites"
              />
            );
          }}
        />
      </Grid>

      <ShotTypeFilter
        selectedShotTypes={selectedShotTypes}
        setSelectedShotTypes={setSelectedShotTypes} />
      {/* <Grid item xs={12}>
        <Autocomplete
          color="secondary"
          disableCloseOnSelect
          fullWidth
          size="small"
          limitTags={1}
          multiple
          value={selectedShotTypes}
          inputValue={shotTypeInput}
          onInputChange={(e, newVal, reason) => {
            if (reason !== "reset") setShotTypeInput(newVal)
          }}
          onChange={(e, newVal) => {
            setSelectedShotTypes(newVal);
          }}
          options={shotTypes}
          // blurOnSelect={false}
          // clearOnBlur={false}

          getOptionLabel={(playType) => playType}
          filterSelectedOptions
          renderInput={(params) => {
            return (
              <TextField
                onBlur={() => setShotTypeInput('')}
                {...params}
                color="secondary"
                InputLabelProps={{ shrink: true }}
                label="Shot type"
                // placeholder="Favorites"
              />
            );
          }}
        />
      </Grid> */}
    </>
  );
}
